import React, { useState, useCallback, useEffect } from "react";
import ReportMotifsTable from "../../../Components/Reports/Motifs/ReportMotifsTable";
import ReportsService from "../../../Services/ReportsService";
import { Skeleton, Row, Col, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { report_motif_actions } from "../../../Commun/constant";
import { useSelector } from "react-redux";
import AddReportMotif from "./AddReportMotif";
export default function ReportMotifs() {
  const { period } = useSelector((state) => state.usersReducer);
  const [reportMotifsList, setReportMotifsList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [motifModel, setMotifModal] = useState(false);
  const [motifUpdateModel, setMotifUpdateModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState(null);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(null);
  const fetch_report_motifs = (page, limit, filter) => {
    setError(false);
    setIsLoading(true);
    ReportsService.getAllReportMotifs(page, limit, filter)
      .then(({ data }) => {
        console.log("AUDIO DATA :: ", data);
        setReportMotifsList(data.data);
        setTotalItems(data.attrs?.total || 0);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  };
  const handleActions = useCallback((action) => {
    switch (action) {
      case "Add Motif": {
        setMotifModal(!motifModel);
        return;
      }
      case "Update Motif": {
        setMotifUpdateModal(!motifUpdateModel);
        return;
      }
    }
  }, []);
  const handleOpenAddModal = useCallback(
    (status) => {
      setMotifModal(!motifModel);
      if (status) fetch_report_motifs(1, limit, filter);
    },
    [motifModel]
  );
  const handleOpenUpdateModal = useCallback(
    (status) => {
      setMotifUpdateModal(!motifUpdateModel);
      if (status) fetch_report_motifs(1, limit, filter);
    },
    [motifUpdateModel]
  );

  const handlePageChange = useCallback(
    (page) => {
      console.log("HELLO TEST FILTER FROM PAGE");
      setPage(page);
      setFilter({ ...filter, page });
    },
    [page, filter]
  );

  const handleLimitChange = useCallback(
    (limit) => {
      console.log("HELLO TEST FILTER FROM LIMIT");
      setLimit(limit);
      setFilter({ ...filter, limit });
    },
    [limit]
  );
  const handleSearch = useCallback(
    (text) => {
      console.log("HELLO TEST FILTER FROM SERACH", text.target.value);

      if (text.target.value.length > 0) {
        console.log("there is a text");
        setSearchTxt(text.target.value);
        setPage(1);
        setFilter({ ...filter, searchTxt: text.target.value, page: 1 });
      } else {
        console.log("there is no text");

        setSearchTxt(null);
        let newFilter = filter;
        delete newFilter.searchTxt;
        newFilter.page = 1;
        console.log("there is no text", newFilter);

        setFilter(newFilter);
      }
    },
    [searchTxt]
  );
  useEffect(() => {
    fetch_report_motifs();
    return () => {};
  }, []);
  useEffect(() => {
    console.log("HANDLE CHANGE ");
    fetch_report_motifs(page, limit, filter);
    return () => {};
  }, [filter, searchTxt]);
  return (
    <div>
      <Row
        style={{ marginBottom: "20px" }}
        align={"middle"}
        justify={"space-between"}
      >
        <Col>
          <ActionButtonGroup
            actions={report_motif_actions}
            handleSelectedAction={handleActions}
          />
        </Col>
        <Col>
          <Input
            style={{
              borderRadius: "20px",
              width: "150px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
            size="default"
            placeholder="Search"
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>

      {error ? (
        <div>error</div>
      ) : isLoading ? (
        <Skeleton />
      ) : (
        <ReportMotifsTable
          motifs={reportMotifsList}
          total={totalItems}
          currentPage={page}
          currentLimit={limit}
          onPage={handlePageChange}
          onLimit={handleLimitChange}
          handleOpenUpdateModal={handleOpenUpdateModal}
          updateVisible={motifUpdateModel}
        />
      )}

      <AddReportMotif visible={motifModel} open={handleOpenAddModal} />
    </div>
  );
}
const ActionButtonGroup = ({ actions, items, handleSelectedAction }) => {
  const [selectedAction, setSelectedAction] = useState(-1);
  const [options, setOptions] = useState(items);
  const handleActionChange = (index, action) => {
    setSelectedAction(index);
    handleSelectedAction(action);
  };
  useEffect(() => {
    setOptions(items);
    return () => {};
  }, [items]);
  return (
    <Row className={"btn-group"}>
      {actions.map((x, index) => (
        <Button
          onClick={() => handleActionChange(index, x)}
          className={"selected-btn "}
          key={index}
        >
          {x}
        </Button>
      ))}
    </Row>
  );
};
