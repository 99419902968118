import { FETCH_POSTS_FAIL, FETCH_POSTS_SUCCESS } from "../actions";
const initialState = {
  isLoading: true,
  postsList: [],
  total_posts: 0,
  page: 1,
  limit: 10,
  error: null,
  findOptionsPosts: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_POSTS_SUCCESS:
      const metadata = payload?.metadata.length > 0;
      console.log("POST REDUCER ", payload?.metadata[0].where);
      return {
        ...state,
        isLoading: false,
        postsList: payload?.data,
        total_posts: metadata
          ? payload?.metadata[0].totalItems
          : state.total_posts,

        findOptionsPosts: metadata ? payload?.metadata[0].where : state.filters,
      };
    case FETCH_POSTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
        postsList: [],
      };
    default:
      return state;
  }
}
