import { api } from "../Config/config";
api.setAccessToken(localStorage.getItem("accesstoken"));

class AudioService {
  async getAllAudioFiles(page, limit, filter) {
    try {
      console.log("FILTER MUISIC FROM SERVICE ", filter);
      const period = await localStorage.getItem("period");
      console.log("FILTER MUISIC FROM SERVICE ", filter, period);
      let params = {};
      if (period && period !== ",") params.period = period;
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (filter) params = { ...params, ...filter };
      const { data } = await api.get(`/musics`, { params });
      console.log(data);
      return data;
    } catch (e) {
      console.log("----sdfsdfsddf------dsfsfsd--", e);
    }
  }
  async insertAudio(audio) {
    try {
      console.log(audio, "AUDIO");
      const form = new FormData();
      form.append("name", audio.name);
      form.append("author", audio.author);
      form.append("gender", audio.gender);
      audio.audio.map((sound) =>
        form.append("audio", sound.originFileObj, sound.filename)
      );
      audio.AlbumImage.map((alb) =>
        form.append("cover", alb.originFileObj, alb.filename)
      );

      const { data } = await api.post(`/musics/upload`, form);
      console.log(data);
      return data;
    } catch (e) {
      console.log("error while upload audio", e);
    }
  }
  async updateAudio(audio) {
    try {
      const form = new FormData();
      form.append("id", audio.id);
      form.append("name", audio.name);
      form.append("author", audio.author);
      form.append("gender", audio.gender);
      form.append("status", audio.status);

      audio?.audio?.map((sound) =>
        form.append("audio", sound?.originFileObj, sound?.filename)
      );
      audio?.AlbumImage?.map((alb) =>
        form.append("cover", alb?.originFileObj, alb?.filename)
      );

      const { data } = await api.put(`/musics/update`, form);
      return data;
    } catch (e) {
      console.log("error while updating an audio", e);
    }
  }
  async deleteAudio(music_id) {
    try {
        const data = await api(`/musics/deleteMusic`, {
            method: "DELETE",
            data: { music_id }
        });
        console.log(" this is the request result :", data);
        return data;
    } catch (error) {
        console.log("error :", error);
    }
}
}
export default new AudioService();
