import React, { useState, useEffect } from "react";
import { Row, Col, Image, Modal } from "antd";
import FsLightbox from "fslightbox-react";
import ReactPlayer from "react-player/lazy";

import "./previewModal.css";
const elements = [
  {
    src: "https://img.freepik.com/free-photo/abstract-flowing-neon-wave-background_53876-101942.jpg?size=626&ext=jpg",
    thumbnail:
      "https://img.freepik.com/free-photo/abstract-flowing-neon-wave-background_53876-101942.jpg?size=626&ext=jpg",
    caption: "Commodo commodo dolore",
    width: 1024,
    height: "auto",
  },
  {
    src: "https://img.freepik.com/free-photo/abstract-flowing-neon-wave-background_53876-101942.jpg?size=626&ext=jpg",
    thumbnail:
      "https://img.freepik.com/free-photo/abstract-flowing-neon-wave-background_53876-101942.jpg?size=626&ext=jpg",
    caption: "Commodo commodo dolore",
    width: 1024,
    height: "auto",
  },
  {
    src: " https://www.youtube.com/watch?v=aS1no1myeTM",
    thumbnail:
      "https://www.simple-react-lightbox.dev/docs/gallery/thumbnails/unsplash05.jpg",
    caption: "Vimeo video",
    autoplay: false,
    showControls: true,
  },
];
const options = {
  settings: {
    overlayColor: "rgba(255,255,255,0.2)",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: "#DDDDDD",
    iconColor: "rgba(126, 172, 139, 0.8)",
  },
  caption: {
    captionColor: "#a6cfa5",
    captionFontFamily: "Raleway, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  },
};
const MediaPerview = ({ items, visible, close, slider }) => {
  const [medias, setMedias] = useState([]);
  console.log(medias);
  useEffect(() => {
    close();
    if (items) {
      let elm = items.map((x) =>
        x.type === "image" ? (
          <img src={x.url} width="auto" height="auto" />
        ) : (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "black",
            }}
          >
            <video
              controls
              width="100%"
              height="100%"
              src={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${x.compressed_video}`}
            />
          </div>
        )
      );
      setMedias(elm);
    }
    return () => {};
  }, []);
  return (
    <FsLightbox
      toggler={visible}
      sources={medias}
      slide={slider}
      options={options}
      onClose={close}
      onOpen={close}
      onInit={close}
      onShow={close}
    />
  );
};

export default MediaPerview;
