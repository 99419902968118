import react, { useState, useRef, useMemo } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";

import world from "../../assets/constants/world.json";
const { Option } = Select;

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  onChange,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchUserList(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      showSearch
      {...props}
      style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
    >
      {options.map((x) => (
        <Option key={x.code} value={x.code}>
          {x.name}
        </Option>
      ))}
    </Select>
    // <Select
    //   labelInValue
    //   filterOption={true}
    //   onSearch={debounceFetcher}
    //   notFoundContent={fetching ? <Spin size="small" /> : null}
    //   {...props}
    //   style={{ width: 200 }}
    //   options={options}
    // />
  );
} // Usage of DebounceSelect

const fetchUserList = async (username) => {
  console.log("fetching user", username);
  return world.map((country) => ({
    label: `${country.name}`,
    value: country.code,
    key: country.code,
  }));
};

export default DebounceSelect;
