import { PictureOutlined, YoutubeFilled } from "@ant-design/icons";
import { Pagination, Row, Table } from "antd";
import React, { useCallback, useState } from "react";
import PostDetails from "../../Screens/posts/postDetails";

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    //disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};
const size = 40;
const PostsTable = ({
  data,
  total,
  currentLimit,
  currentPage,
  onLimit,
  onPage,
}) => {
  const posts_columns = [
    {
      title: "",
      dataIndex: "medias",
      render: (media, post) =>
        media?.images.length > 0 ? (
          <PictureOutlined style={{ fontSize: "20px" }} />
        ) : media?.videos.length > 0 ? (
          <YoutubeFilled style={{ fontSize: "20px" }} />
        ) : post?.post_parent_id?.medias?.images?.length > 0 ? (
          <PictureOutlined style={{ fontSize: "20px" }} />
        ) : post?.post_parent_id?.medias?.videos.length > 0 ? (
          <YoutubeFilled style={{ fontSize: "20px" }} />
        ) : null,
    },
    {
      title: "Post",
      dataIndex: "description",
      render: (text, post) => (
        <a
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            handleOpenPostDetails(post);
          }}
        >
          <div>
            {
              //When the posts is refeechred we take the media from post_parent_id
              post?.post_parent_id?.medias?.images?.length > 0 ? (
                <img
                  style={{
                    width: "60px",
                    height: "55px",
                    borderRadius: "12px",
                    marginRight: "8px",
                  }}
                  src={post?.post_parent_id?.medias?.images[0]?.url}
                />
              ) : post?.post_parent_id?.medias?.videos?.length > 0 ? (
                <img
                  style={{
                    width: "60px",
                    height: "55px",
                    borderRadius: "12px",
                    marginRight: "8px",
                  }}
                  src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${post?.post_parent_id?.medias?.videos[0]?.thumbnail}`}
                />
              ) : post.medias?.images && post.medias?.images?.length ? (
                <img
                  style={{
                    width: "60px",
                    height: "55px",
                    borderRadius: "12px",
                    marginRight: "8px",
                  }}
                  src={post?.medias?.images[0]?.url}
                />
              ) : (
                post.medias?.videos &&
                post.medias?.videos?.length > 0 && (
                  <img
                    style={{
                      width: "60px",
                      height: "55px",
                      borderRadius: "12px",
                      marginRight: "8px",
                    }}
                    src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${post?.medias?.videos[0]?.thumbnail}`}
                  />
                )
              )
            }
          </div>
          <div style={{ maxWidth: "450px" }}>
            <p>{post.description}</p>
          </div>
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "age",
      responsive: ["md"],
    },
    {
      title: "Views",
      dataIndex: "post_views_total",
    },
    {
      title: "Likes",
      dataIndex: "post_likes_total",
    },
    {
      title: "Refeecher",
      dataIndex: "address",
    },
    {
      title: "Comments",
      dataIndex: "post_comments_total",
    },
    {
      title: "Shares",
      dataIndex: "address",
    },
  ];
  const [selectionType, setSelectionType] = useState("checkbox");
  const [showPostDetails, setShowPostDetails] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const handleOpenPostDetails = useCallback(
    (post) => {
      setShowPostDetails(!showPostDetails);
      !showPostDetails === true
        ? setSelectedPost(post)
        : setSelectedPost(false);
    },
    [showPostDetails]
  );
  return (
    <div>
      <Table
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        columns={posts_columns}
        dataSource={data?.map((x, i) => ({ ...x, key: i }))}
        pagination={false}
      />
      <Row justify={"end"} style={{ marginTop: "20px" }}>
        <Pagination
          className={"pagination"}
          defaultCurrent={1}
          current={Number(currentPage)}
          total={total}
          onChange={(page, pageSize) => {
            if (page !== currentPage) onPage(page);
            if (pageSize !== currentLimit) onLimit(pageSize);
          }}
        />
      </Row>

      {showPostDetails && (
        <PostDetails
          visible={showPostDetails}
          open={handleOpenPostDetails}
          item={selectedPost}
        />
      )}
    </div>
  );
};

export default PostsTable;
