import {
  Col,
  Modal,
  Row,
  Image,
  Space,
  Upload,
  message,
  Form,
  Input,
  Switch,
  Button,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import LanguageComponent from "../../Components/Categories/LanguageComponent";
import React, { useEffect, useState } from "react";
import CategoryService from "../../Services/CategoryService";
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function beforeUploadAlbumImage(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M ? true : Upload.LIST_IGNORE;
}
function beforeUploadAudio(file) {
  const isAudio = file.type === "audio/mpeg" || file.type === "audio/x-ms-wma";
  if (!isAudio) {
    message.error("You can only upload mp3/wma file!");
  }

  return isAudio ? true : Upload.LIST_IGNORE;
}
const normFile = (e) => {
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 6 },
    sm: { span: 24, offset: 6 },
  },
};
const UpdateMusic = ({ visible, open, item }) => {
  const [form] = Form.useForm();

  const [Files, setFiles] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [
      {
        uid: "-1",
        name: "xxx.png",
        status: "done",
        url: item?.albumImage,
        thumbUrl: item?.albumImage,
        type: "image/jpeg",
      },
      {
        uid: "-1",
        name: "xxx.mp3",
        status: "done",
        url: item?.s3Url,
        thumbUrl: item?.albumImage,
        type: "audio/mpeg",
      },
    ],
  });
  function aud_play_pause() {
    var myAudio = document.getElementById("myTune");
    myAudio.pause();
  }
  const handleCancel = () => {
    Files.fileType === "audio" && aud_play_pause();
    setFiles({ ...Files, fileList: [], previewVisible: false });
  };

  const handlePreview = async (file) => {
    console.log(file.type);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setFiles({
      ...Files,
      previewImage:
        process.env.REACT_APP_BASE_S3_VIDEO_URL + file.url || file.preview,
      fileType:
        file.type === "audio/mpeg" || file.type === "audio/x-ms-wma"
          ? "audio"
          : "image",
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  console.log("CATEGORY ITEM :: ", item);
  const handleChange = (file) =>
    setFiles({ ...Files, fileList: [...Files.fileList, ...file.fileList] });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    values.id = item.category_id;
    if (values.catImage[0].uid === "-1") delete values.catImage;
    let lang = {};
    values.languages.map((x) => {
      let keys = Object.keys(x);
      lang[x.language] = x.word;
      return lang;
    });
    lang["en"] = values.categoryName;
    values.languages = lang;
    console.log("values", values);
    open(false);
    CategoryService.updateCategory(values).then(() => open(true));
  };
  const onReset = () => {
    form.resetFields();
    open(true);
  };
  useEffect(() => {
    // form.setFields({ audio: "test" });
  }, []);
  return (
    item && (
      <div>
        <Modal
          centered
          title={"Update audio"}
          visible={visible}
          onOk={() => open()}
          onCancel={() => {
            open();
            onReset();
          }}
          footer={null}
          destroyOnClose
          width={"50%"}
        >
          <Row gutter={16}>
            <Form
              form={form}
              {...layout}
              style={{ width: "100%", padding: 30 }}
              labelAlign="left"
              name="nest-messages"
              onFinish={onFinish}
              initialValues={{
                ...item,
                languages: Object.keys(item.language).map((x) => {
                  let option = {};
                  option.language = x;
                  option.word = item.language[x];
                  console.log("IM THE OPTION LANG :", option);
                  return option;
                }),
              }}
              validateMessages={validateMessages}
            >
              <Form.Item
                name="catImage"
                label="Category image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                initialValue={[
                  {
                    uid: "-1",
                    name: item?.name,
                    status: "done",
                    url: item?.categoryImageUrl,
                    thumbUrl:
                      process.env.REACT_APP_BASE_S3_VIDEO_URL +
                      item?.categoryImageUrl,
                    type: item?.mimeType,
                  },
                ]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Upload
                  name="logo"
                  maxCount={1}
                  listType="picture-card"
                  customRequest={dummyRequest}
                  fileList={Files.fileList}
                  beforeUpload={beforeUploadAlbumImage}
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item
                name={"categoryName"}
                label="Default title (EN)"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Default category title (EN)" />
              </Form.Item>
              <Form.List
                name="languages"
                rules={[
                  {
                    required: true,
                    validator: async (_, names) => {
                      if (!names || names.length < 2) {
                        return Promise.reject(
                          new Error("At least 2 languages")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0
                          ? formItemLayout
                          : formItemLayoutWithOutLabel)}
                        label={index === 0 ? "Traductions" : ""}
                        required={false}
                        key={field.key}
                      >
                        <Row>
                          <Col span={fields.length > 1 ? 22 : 24}>
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  // whitespace: true,
                                  message:
                                    "Please input language or delete this field.",
                                },
                              ]}
                              noStyle
                            >
                              <LanguageComponent value={{ word: "sdfsdf" }} />
                            </Form.Item>
                          </Col>
                          <Col>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                style={{ marginLeft: 10 }}
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}
                    <Form.Item {...tailLayout}>
                      <Row justify="center">
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                        >
                          Add Language
                        </Button>

                        <Form.ErrorList errors={errors} />
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item {...tailLayout}>
                <Row gutter={16} justify="center">
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      htmlType="button"
                      onClick={onReset}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Row>
          <Modal
            visible={Files.previewVisible}
            title={Files.previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            {Files.fileType === "image" ? (
              <img
                alt="example"
                style={{ width: "100%" }}
                src={Files.previewImage}
              />
            ) : (
              <audio
                style={{ width: "100%" }}
                id="myTune"
                controls
                src={Files.previewImage}
              >
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            )}
          </Modal>
        </Modal>
      </div>
    )
  );
};

export default UpdateMusic;
