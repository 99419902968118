import {
  AntDesignOutlined,
  CheckCircleFilled,
  StarFilled,
} from "@ant-design/icons";
import { Avatar, Col, Pagination, Row, Table } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
const UsersTable = ({ data, total, page, onPage, onLimit }) => {
  const history = useHistory();
  const size = 40;
  const user_columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text, user) => (
        <Row gutter={16} align={"middle"}>
          <Col>
            <Avatar
              size={30}
              icon={<AntDesignOutlined />}
              src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${user.profile_image}`}
            />
          </Col>
          <Col>{text}</Col>
        </Row>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      responsive: ["md"],
      render: (text, user) => (
        <a
          onClick={() => {
            history.push(`users-profile/${user.user_id}`);
          }}
        >
          {text ? text + " " : "-"}
        </a>
      ),
    },
    {
      title: "Posts",
      dataIndex: "posts_count",
      key: "posts_count",
      responsive: ["md"],
      render: (text) => <div>{text} posts</div>,
    },
    {
      title: "Views",
      dataIndex: "views_count",
      key: "views_count",
      responsive: ["lg"],
    },
    {
      title: "Followers",
      dataIndex: "followers_count",
      key: "followers",
      responsive: ["lg"],
    },
    {
      title: "Following",
      dataIndex: "following_count",
      key: "following",
      responsive: ["lg"],
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      responsive: ["lg"],
      render: (country, user) => <div>{user.country + " " + user.city}</div>,
    },
    {
      title: "",
      dataIndex: "status",
      key: "id",
      responsive: ["lg"],
      render: (obj, user) => (
        <Row gutter={16}>
          <Col>
            <StarFilled
              style={{
                fontSize: "24px",
                color: user.isTalent ? "red" : "gray",
              }}
            />
          </Col>
          <Col>
            <CheckCircleFilled
              style={{
                fontSize: "24px",
                color: user.isVerified ? "#08c" : "gray",
              }}
            />
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <div>
      <Table
        columns={user_columns}
        dataSource={data}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
      />
      <Row justify={"end"} style={{ marginTop: "20px" }}>
        <Pagination
          defaultCurrent={1}
          current={page}
          total={total}
          onChange={(page, pageSize) => {
            onPage(page);
            onLimit(pageSize);
          }}
        />
      </Row>
    </div>
  );
};

export default UsersTable;
