import React from "react";
import ERRORIMAGE from "../assets/img/feechr-logo.jpg";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div>
      <img src={ERRORIMAGE} />
      <p style={{ textAlign: "center" }}>
        <Link to="/">Go to Home </Link>
      </p>
    </div>
  );
};

export default Error;
