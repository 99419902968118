import React from "react";
import { Col, Row, Card, Tooltip, Progress } from "antd";
import "./hashtags_card.css";
import { AntDesignOutlined } from "@ant-design/icons";
const gridStyle = {
  width: "33.33333%",
  border: "none ",
  boxShadow: "none",
  padding: "9px",
};
const data = [1, 2, 3, 4, 5, 6, 7];
const HashTagsCard = () => {
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={10}
      xl={10}
      style={{ backgroundColor: "white", padding: "20px" }}
      className={"hashtags"}
    >
      <Card title="Top Regions" className={"card_container customheader "}>
        {data.map((x) => (
          <Row>
            <Col style={gridStyle}>#region </Col>
            <Col style={gridStyle}>255865 views</Col>
            <Col style={gridStyle}>
              <Tooltip title="hghjghjgj">
                <Progress percent={60} showInfo={false} />
              </Tooltip>
            </Col>
          </Row>
        ))}
      </Card>
    </Col>
  );
};

export default HashTagsCard;
