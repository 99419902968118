import { AntDesignOutlined, UserOutlined, } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Layout,
  Menu,
  Row,
  Divider
} from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { FaHashtag, FaRegUser } from "react-icons/fa";
import { GiMusicalNotes } from "react-icons/gi";
import { HiOutlinePhotograph, HiOutlineUserGroup, HiOutlineKey } from "react-icons/hi";
import { IoNotificationsOutline } from "react-icons/io5";
import {
  RiDashboardLine,
  RiLogoutBoxLine,
  RiShieldFlashLine,
  RiCheckboxCircleLine,
  RiPinDistanceLine,
  RiGroupLine
} from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getRouteName } from "../../Helpers/utils";
import { fetch_posts } from "../../redux/posts/action";
import { fetch_users } from "../../redux/users/action";
import AuthService from "../../Services/AuthService";
import "./mainLayout.css";
import useWindowWidth from "../../CustomHooks/useWindowWidth";
const { Header, Footer, Sider, Content } = Layout;
const menuIconStyle = { marginTop: "8px", marginLeft: "-4px" };

const { RangePicker } = DatePicker;

const MainLayout = ({ children }, ...props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const width = useWindowWidth();
  const period = localStorage.getItem("period")?.split(",");
  const routeName = getRouteName(location.pathname);
  const [collapsed, setCollapsed] = useState(false);
  const { findOptionsUsers } = useSelector((state) => state.usersReducer);
  const { findOptionsPosts } = useSelector((state) => state.postsReducer);
  const { user } = useSelector((state) => state.authReducer);


  // console.log("Posts FIND OPTIONS :", findOptionsPosts);
  let sideMenu = [
    {
      title: "OverView",
      path: "/app/home",
      key: 1,
      icon: (
        <RiDashboardLine
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "Users",
      path: "/app/users",
      key: 2,
      icon: (
        <FaRegUser
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "Posts",
      path: "/app/posts",
      key: 3,
      icon: (
        <HiOutlinePhotograph
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "events",
      path: "/app/events",
      key: 4,
      icon: (

        <RiPinDistanceLine
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "clubs",
      path: "/app/clubs",
      key: 5,
      icon: (

        <HiOutlineUserGroup
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "Tags",
      path: "/app/hashtags",
      key: 6,
      icon: (
        <FaHashtag
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "Category",
      path: "/app/categories",
      key: 7,
      icon: (
        <BiCategory
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "Audio Library",
      path: "/app/musics",
      key: 8,
      icon: (
        <GiMusicalNotes
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "Reports",
      path: "/app/reports",
      key: 9,
      icon: (
        <RiShieldFlashLine
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    {
      title: "verification",
      path: "/app/verification-requests",
      key: 10,
      icon: (
        <RiCheckboxCircleLine
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    },
    // localStorage.getItem("role") == 'superAdmin' && {
    //   title: "Manage admins",
    //   path: "/app/manage-admins",
    //   key: 11,
    //   icon: (
    //     <HiOutlineKey
    //       size={24}
    //       style={(collapsed || width < 1300) && menuIconStyle}
    //     />
    //   ),
    // }
  ];

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const handlePeriod = (date) => {
    delete findOptionsPosts?.period;
    delete findOptionsUsers?.period;
    if (date[0] === "" || date[1] === "") {
      localStorage.setItem("period", ",");
      dispatch({ type: "SET_PERIOD", payload: "," });
    } else {
      dispatch({ type: "SET_PERIOD", payload: date });
      localStorage.setItem("period", date);
      if (location.pathname === "/app/users") {
        dispatch(fetch_users(1, 10, findOptionsUsers));
      } else if (location.pathname === "/app/posts")
        dispatch(fetch_posts(null, findOptionsPosts));
    }
  };

  const menu = (
    <Menu onClick={handlePeriod} selectedKeys={1}>
      {/* {dateFilter.map((x, index) => (
        <Menu.Item
          className={"period-menu"}
          style={{
            backgroundColor:
              filterDate === dateFilter[index] ? "black" : "white",
            color: filterDate === dateFilter[index] ? "white" : "black",
          }}
          key={index}
        >
          {x}
        </Menu.Item>
      ))} */}
    </Menu>
  );
  return (

    ((localStorage.getItem("role") == 'superAdmin') ? sideMenu.push({
      title: "Manage admins",
      path: "/app/manage-admins",
      key: 11,
      icon: (
        <HiOutlineKey
          size={24}
          style={(collapsed || width < 1300) && menuIconStyle}
        />
      ),
    }) : ""),


    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className={"right-sidebar-background"}
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          zIndex: 10,
        }}
        width={"16%"}
        trigger={false}
        collapsible
        collapsed={collapsed || width < 1300}
        onCollapse={onCollapse}
      >
        <div>
          <Menu
            mode="inline"
            defaultSelectedKeys={["0"]}
            style={{
              padding: "20px",
            }}
          >

            {sideMenu.map((item, index) => (
              //  if (localStorage.getItem("role") == 'superAdmin') {
              //   console.log("sideMenusideMenusideMenusideMenusideMenusideMenu 11111",sideMenu);
              //   sideMenu.pop();
              //   console.log("sideMenusideMenusideMenusideMenusideMenusideMenu 22222",sideMenu);

              // }
              <Menu.Item
                key={index}
                icon={item.icon}
                onClick={() => history.push(item.path)}
              >
                {item.title}
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className="logout">
          {/* <a onClick={AuthService.logout}> */}
          <Row className={localStorage.getItem("role") == 'superAdmin' ? "rowLogoutAdmin" : "rowLogoutSuper"}  >
            <a onClick={AuthService.logout}>
              <Col>
              <Divider/>
                <RiLogoutBoxLine style={{ fontSize: "30px" }} />
              </Col>
              <Col>LOGOUT</Col>
            </a>
          </Row>
          {/* </a> */}
          {/* <Button
            type="text"
            
            style={{ textAlign: "center" }}
            icon={
              
            }
          >
            LOGOUT
          </Button> */}
        </div>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed || width < 1300 ? "4%" : "16%" }}
      >
        <Header
          className={"custom_header_layout"}
          style={{
            position: "fixed",
            left: "auto",
            zIndex: 1,
            width: collapsed || width < 1300 ? "96%" : "84%",
          }}
        >
          <Row className={"custom-header-container"}>
            <div className={"title"}>{routeName}</div>
            <div className={"right-side-header"}>
              <Row gutter={16}>
                <Col>
                  {/* <RangePicker
                    defaultValue={[
                      period[0] ? moment(period[0], "YYYY-MM-DD") : "",
                      period[1] ? moment(period[1], "YYYY-MM-DD") : "",
                    ]}
                    onChange={(dates, dateStrings) => handlePeriod(dateStrings)}
                  /> */}
                </Col>
                <Col>
                  {/* <Input
                    style={{
                      borderRadius: "20px",
                      width: "150px",
                      marginRight: "10px",
                    }}
                    size="default"
                    placeholder="Search"
                    prefix={<UserOutlined />}
                  /> */}
                </Col>
              </Row>

              <Row gutter={12}>
                <Col>
                  <Avatar
                    size={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 40 }}
                    icon={<AntDesignOutlined />}
                  />
                </Col>
                <Col>
                  <Dropdown overlay={menu}>
                    <span>{user.firstName} </span>
                  </Dropdown>
                </Col>
                <Col style={{ padding: "5px" }}>
                  {/* <Badge count={1} size={"small"}>
                    <IoNotificationsOutline style={{ fontSize: "20px" }} />
                  </Badge> */}
                </Col>
              </Row>
            </div>
          </Row>
        </Header>
        <Content className="container">
          <main>
            <div style={{ padding: 24, minHeight: 380 }}>{children}</div>
          </main>
        </Content>
       
      </Layout>
    </Layout>
  );
};

export default MainLayout;
