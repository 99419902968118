import React, { useState, useCallback } from "react";
import { Pagination, Row, Table, Tag, Button, Modal } from "antd";
import CategoryDetails from "../../Screens/category/CategoryDetails";
import UpdateCategory from "../../Screens/category/UpdateCategory";
import CategoryService from "../../Services/CategoryService";
import {
  DeleteOutlined,
} from "@ant-design/icons";

const size = 40;
export default function MusicTable({
  data,
  total,
  currentLimit,
  currentPage,
  onLimit,
  onPage,
  updateVisible,
  handleOpenUpdateModal,
}) {
  const [selectionType, setSelectionType] = useState("radio");
  const [showAudioDetails, setShowAudioDetails] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [category, setCategory] = useState();

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      selectedRows?.length > 0 && setSelectedAudio(selectedRows[0]);
    },
    getCheckboxProps: (record) => ({
      //disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const audio_columns = [
    {
      title: "",
      dataIndex: "medias",
      render: (media) =>
        // media?.images.length > 0 ? (
        //   <PictureOutlined style={{ fontSize: "20px" }} />
        // ) : media?.videos.length > 0 ? (
        //   <YoutubeFilled style={{ fontSize: "20px" }} />
        // ) :
        null,
    },
    {
      title: "Title",
      dataIndex: "categoryName",
      render: (text, category) => (
        <a
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            handleOpenAudioDetails(category);
          }}
        >
          <div>
            <img
              style={{
                width: "60px",
                height: "55px",
                borderRadius: "12px",
                marginRight: "8px",
              }}
              src={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${category.categoryImageUrl}`}
            />
          </div>
          <div style={{ maxWidth: "450px" }}>
            <p>{category.categoryName}</p>
          </div>
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, category) =>
        category.active === "Y" ? (
          <Tag color="default">Enabled</Tag>
        ) : (
          <Tag color="default">Disabled</Tag>
        ),
    },
    {
      title: "remove",
      render: (text, category) =>
        <DeleteOutlined onClick={() => showModal(category)}>
          Delete Category
        </DeleteOutlined>
    },
  ];

  const showModal = (category) => {
    setCategory(category)
    setIsModalVisible(true);
  };

  const handleOk = (e) => {
    CategoryService.deleteCategory(e.category_id).then(()=>{
      window.location.reload()
    })
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const handleOpenAudioDetails = useCallback(
    (audio) => {
      setShowAudioDetails(!showAudioDetails);
      !showAudioDetails === true
        ? setSelectedAudio(audio)
        : setSelectedAudio(false);
    },
    [showAudioDetails]
  );
  return (
    <div>

      <Modal title="Delete Category" visible={isModalVisible} onOk={() => handleOk(category)} onCancel={handleCancel}>
        Would you delete this category ?
      </Modal>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={audio_columns}
        dataSource={data?.map((x, i) => ({ ...x, key: i }))}
        pagination={false}
      />
      <Row justify={"end"} style={{ marginTop: "20px" }}>
        <Pagination
          className={"pagination"}
          defaultCurrent={1}
          current={Number(currentPage)}
          total={total}
          onChange={(page, pageSize) => {
            if (page !== currentPage) onPage(page);
            if (pageSize !== currentLimit) onLimit(pageSize);
          }}
        />
      </Row>

      {showAudioDetails && (
        <CategoryDetails
          visible={showAudioDetails}
          open={handleOpenAudioDetails}
          item={selectedAudio}
        />
      )}
      <UpdateCategory
        visible={updateVisible}
        open={handleOpenUpdateModal}
        item={selectedAudio}
      />
    </div>
  );
}
