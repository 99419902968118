import { Button, Card, Checkbox, Col, Form, Input, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { history } from "../../Helpers/history";
import { forgetPassword } from "../../redux/auth/action"; //"redux/auth/action";
import { useHistory } from "react-router-dom";
import {
  DoubleLeftOutlined
} from "@ant-design/icons";
import Logo from "./../../assets/img/Logo.png";


const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const style = {};
const ForgetComponent = (props) => {
  let history = useHistory();
  const { message, email } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  // const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const onFinish = (values) => {
    dispatch(forgetPassword(values.user.email))
    localStorage.setItem("email", values.user.email);

  };
 
  useEffect(() => {
    if (message)
      if (message == "CODE.SENT.FOR.PASSWORD.RESET") {
        history.push("/authentication/reset-password");
      }
  }, [message])

  if (message == "Request failed with status code 400") {
    alert('email not valid');
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    

    <div style={{ backgroundColor: "#FFDDFed", paddingTop:'160px' }}>
            <img src={Logo} className="logoImg"/>
      <Row gutter={[0, 0]}>
        <Col className="gutter-row" span={3}>
          {/* <div style={style}>col-6</div> */}
        </Col>
        <Col className="gutter-row" span={18}>
          <Row justify={"center"} align={"middle"} >
            <Card
              size="default"
              title="forget password"
              extra={<Button type="link" htmlType="submit">
              <a href="/authentication/login"><DoubleLeftOutlined/> Login </a>
              </Button>}
              style={{ width: "70%" }}
            >
              <div style={style}>
              <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 8,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item name={['user', 'email']} label="Email"
          rules={[{
            type: 'email',
            message: "Please input your email!",
          }]}>
          <Input value={email}
          // onChange={(val) => setemail(val.target.value)}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            continue
          </Button>
        </Form.Item>
      </Form>
              </div>
            </Card>
          </Row>
        </Col>
       
      </Row>
    </div>





    
  );
};

// async function mapStateToProps(state) {
//   const { isLoggedIn, user } = state.authReducer;

//   return {
//     isLoggedIn,
//     user,
//   };
// }
export default ForgetComponent;

