import { api } from "../Config/config";
api.setAccessToken(localStorage.getItem("accesstoken"));

class eventServices {

    async fetchEvents(eventTag, limit, page, searchTag) {
        try {
            let object = {}
            console.log(" page :", page);
            if (eventTag) object.eventTag = eventTag
            if (page) object.page = page
            if (limit) object.limit = limit
            if (searchTag) object.searchTag = searchTag
            console.log(" this is the object :", object);
            const { data } = await api(`/fetchEvents`, {
                method: "POST",
                data: object
            });
            console.log(" this is the request result :", data);
            return data;
        } catch (error) {
            console.log("error :", error);
        }
    }

    async deleteEvent(eventArray) {
        try {
            const data = await api(`/deleteEvent`, {
                method: "DELETE",
                data: { eventArray }
            });
            console.log(" this is the request result :", data);
            return data;
        } catch (error) {
            console.log("error :", error);
        }
    }


}
export default new eventServices();
