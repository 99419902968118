import { Col, Modal, Row, Image, Space, Tag, Button, Divider, Form } from "antd";
import React, { useEffect, useState } from "react";
import randomColor from "randomcolor";
import VerificationRequestServices from "../../Services/VerificationAccount";

const VerificationRequestDetails = ({ visible, open, item }) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMotif, setSelectedMotif] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSubmit = () => {
    VerificationRequestServices.agreeVerificationAccount(item.user_id)
        .then(({ data }) => {
          window.location.reload();
        })
        .catch((e) => {
            console.log(e);
            setError(true);
        });
    setIsModalVisible(false);
};


  return (
    <div>
      <Modal
        centered
        title={"Category Details"}
        visible={visible}
        onOk={() => open(null)}
        onCancel={() => open(null)}
        footer={null}
        width={"60%"}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Image src={process.env.REACT_APP_BASE_S3_VIDEO_URL + item.image} />
          </Col>
          <Col span={12}>
            <Space
              style={{
                padding: 20,
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
              }}
              direction="vertical"
            >
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  known_as
                </Col>
                <Col xs={12}>{item?.known_as}</Col>
              </Row>

              {/* <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Supported language
                </Col>

                <Col xs={12}>
                  <Row gutter={[16, 16]} style={{ flex: "flexWrap" }}>
                    {Object.keys(item.language).map((x) => (
                      <Col>
                        <Tag
                          color={randomColor({
                            luminosity: "bright",
                            format: "rgb", // e.g. 'rgb(225,200,20)'
                          })}
                        >
                          {x}
                        </Tag>
                      </Col>
                    ))}
                  </Row>
                </Col>

              </Row> */}

              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  category name
                </Col>
                <Col xs={12}>{item["categories.categoryName"]}</Col>
              </Row>

              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  username
                </Col>
                <Col xs={12}>
                  {item?.username}
                </Col>
              </Row>
              <Row style={{}}>
                <Col
                  xs={12}
                  className={"level-two-title"}
                  style={{ textAlign: "left" }}
                >
                  <div style={{ alignSelf: "left" }}> Date </div>
                </Col>
                <Col xs={12}>{item.createdAt}</Col>
              </Row>
            </Space>
          </Col>
          <Divider/>
        </Row>
        <Form.Item >
                <Row gutter={16} justify="center">
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      htmlType="button"
                      onClick={() => open(null)}
                    >
                      cancel
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
      </Modal>
    </div>
  );
};

export default VerificationRequestDetails;
