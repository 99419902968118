import { api } from "../Config/config";
api.setAccessToken(localStorage.getItem("accesstoken"));

class NotificationService {
  //   async getAllReports(page, limit, filter) {
  //     try {
  //       const period = localStorage.getItem("period");
  //       console.log("FILTER MUISIC FROM SERVICE ", filter, period);
  //       let params = {};
  //       if (period && period !== ",") params.period = period;
  //       if (page) params.page = page;
  //       if (limit) params.limit = limit;
  //       if (filter) params = { ...params, ...filter };
  //       const { data } = await api.get(`/reports`, { params });
  //       console.log(data);
  //       return data;
  //     } catch (e) {
  //       console.log("----sdfsdfsddf------dsfsfsd--", e);
  //     }
  //   }

  async create_new_notification(notif) {
    try {
      const newNotif = await api.post("/notification", notif);
      return newNotif;
    } catch (e) {}
  }
}
export default new NotificationService();
