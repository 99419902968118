import { api } from "../Config/config";
api.setAccessToken(localStorage.getItem("accesstoken"));

class VerificationAccountService {

    async getUsersToVerify(page, limit, filter) {
        console.log("FILTER MUISIC FROM SERVICE ", filter);
        const period = await localStorage.getItem("period");
        let params = {};
        if (period && period !== ",") params.period = period;
        if (page) params.page = page;
        if (limit) params.limit = limit;
        if (filter) params = { ...params, ...filter };
        const data = await api.get(`/verifProfile/getUsersToVerify`, { params });
        return data;
    }

    async agreeVerificationAccount(user_id) {
        const data = await api.post(`/verifProfile/agreeVerificationAccount`, { user_id: user_id });
        return data;
    }


}
export default new VerificationAccountService();
