import { PictureOutlined, YoutubeFilled } from "@ant-design/icons";
import { Pagination, Row, Table } from "antd";
import React, { useCallback, useState } from "react";
import PostDetails from "../../Screens/posts/postDetails";

const UsersActivityTable = ({ data, onLimit, onPage, total, page, currentLimit }) => {
  const activity_columns = [
    {
      title: "",
      dataIndex: "medias",
      render: (media) =>
        media?.images.length > 0 ? (
          <PictureOutlined style={{ fontSize: "20px" }} />
        ) : media?.videos.length > 0 ? (
          <YoutubeFilled style={{ fontSize: "20px" }} />
        ) : null,
      responsive: ["lg"],
    },
    {
      title: "Post",
      dataIndex: "description",
      render: (text, post) => (
        <a
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "400px",
          }}
          onClick={() => handleOpenPostDetails(post)}
        >
          {
            //When the posts is refeechred we take the media from post_parent_id

            post?.post_parent_id?.medias?.images?.length ? (
              <img
                style={{
                  width: "60px",
                  height: "55px",
                  borderRadius: "12px",
                  marginRight: "8px",
                }}
                src={post?.post_parent_id?.medias?.images[0]?.url}
              />
            ) : post?.post_parent_id?.medias?.videos?.length ? (
              <img
                style={{
                  width: "60px",
                  height: "55px",
                  borderRadius: "12px",
                  marginRight: "8px",
                }}
                src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${post?.post_parent_id?.medias?.videos[0]?.thumbnail}`}
              />
            ) : post.medias?.images && post.medias?.images?.length ? (
              <img
                style={{
                  width: "60px",
                  height: "55px",
                  borderRadius: "12px",
                  marginRight: "8px",
                }}
                src={post?.medias?.images[0]?.url}
              />
            ) : post.medias?.videos && post.medias?.videos?.length ? (
              <img
                style={{
                  width: "60px",
                  height: "55px",
                  borderRadius: "12px",
                  marginRight: "8px",
                }}
                src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${post?.medias?.videos[0]?.thumbnail}`}
              />
            ) : null
          }
          <div style={{ maxWidth: "450px" }}>
            <p>{post.description}</p>
          </div>
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "age",
    },
    {
      title: "Views",
      dataIndex: "post_views_total",
    },
    {
      title: "Likes",
      dataIndex: "post_likes_total",
    },
    {
      title: "Refeecher",
      dataIndex: "address",
    },
    {
      title: "Comments",
      dataIndex: "post_comments_total",
    },
    {
      title: "Shares",
      dataIndex: "address",
    },
  ];

  const [selectionType, setSelectionType] = useState("checkbox");
  const [showPostDetails, setShowPostDetails] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);

  const handleOpenPostDetails = useCallback(
    (post) => {
      setShowPostDetails(!showPostDetails);
      setSelectedPost(post);
    },
    [showPostDetails]
  );
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      //disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div style={{ width: "100%" }}>
      <Table
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
        pagination={false}
        columns={activity_columns}
        dataSource={data?.map((x, i) => ({ ...x, key: i }))}
      />
      <Row justify={"end"} style={{ marginTop: "20px" }}>
      <Pagination
          defaultCurrent={1}
          current={Number(page)}
          total={total}
          onChange={(mypage, pageSize) => {
            alert("pagination " + page);
            if (mypage !== page) onPage(mypage);
            if (pageSize !== currentLimit) onLimit(pageSize);
          }}
        />
      </Row>
      {selectedPost && (
        <PostDetails
          visible={showPostDetails}
          open={handleOpenPostDetails}
          item={selectedPost}
        />
      )}
    </div>
  );
};

export default UsersActivityTable;

// const ActionButtonGroup = ({ actions, items }) => {
//   const [selectedAction, setSelectedAction] = useState(-1);
//   const [options, setOptions] = useState(items);
//   const handleActionChange = (index, filter) => {
//     setSelectedAction(index);
//   };
//   useEffect(() => {
//     setOptions(items);
//     return () => {};
//   }, [items]);
//   return (
//     <Row className={"btn-group"}>
//       {items?.length <= 1 ? (
//         actions.map((x, index) => (
//           <Button
//             className={selectedAction === index && "selected-btn"}
//             key={index}
//           >
//             {x}
//           </Button>
//         ))
//       ) : (
//         <Button>Block Post</Button>
//       )}
//     </Row>
//   );
// };
