import React, { useCallback, useState, useEffect } from "react";
import { Row, Col, Image, Button } from "antd";
import ReactPlayer from "react-player/lazy";
import "./previewModal.css";
import MediaPerview from "./MediaPerview";
import playerIcon from "../../assets/img/playerIcon.png";

import { nFormatter } from "../../Helpers/utils";
const PostCard = ({ post }) => {
  const [visible, setVisible] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [slider, setSlider] = useState(1);
  console.log("IMMMM THE ITEM SELECTED ", post);
  const parentMedias = post?.post_parent_id?.medias && [
    ...post?.post_parent_id?.medias?.videos,
    ...post?.post_parent_id?.medias?.images,
  ];
  let medias =
    parentMedias?.length > 0
      ? parentMedias
      : [...post?.medias?.images, ...post?.medias?.videos];

  console.log("HIIIII MEDIA // :: ", medias);
  const pausePlayer = useCallback(() => {
    console.log("hello FROM CALLBACK");

    setPlaying(false);
  }, [playing]);
  const handleTogglePreview = useCallback(
    (index) => {
      setPlaying(false);
      if (index) {
        setSlider(index);
        setVisible(!visible);
      }
    },
    [visible, playing]
  );
  useEffect(() => {
    return () => {
      medias = [];
    };
  }, [post]);
  return !medias ? (
    <div>no media found</div>
  ) : (
    <div className={"card-container"}>
      <Row>
        <Col
          span={24}
          onClick={() => {
            handleTogglePreview(1);
          }}
        >
          {medias.length >= 1 ? (
            medias[0].type === "image" ? (
              <Image
                preview={false}
                width={"100%"}
                style={{ maxHeight: medias.length === 1 ? "500px" : "250px" }}
                className={"card-image-first"}
                src={`${medias[0].url}`}
              />
            ) : medias[0].type === "video" ? (
              <div className={"img-card-container "}>
                <ReactPlayer
                  controls
                  // playIcon={
                  //   <Button type="text">
                  //     <Image
                  //       preview={false}
                  //       width={50}
                  //       height={50}
                  //       src={playerIcon}
                  //     />
                  //   </Button>
                  // }
                  light={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${medias[0].thumbnail}`}
                  playing={playing}
                  width="100%"
                  //onClickPreview={(e) => setPlaying(!playing)}
                  height={medias.length < 2 ? "500px" : "250px"}
                  style={{ maxHeight: medias.length < 2 ? "500px" : "250px" }}
                  className={"card-image-first"}
                  url={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${medias[0].compressed_video}`}
                />
                <div
                  className="overlay-card-items"
                  onClick={() => handleTogglePreview(1)}
                />
              </div>
            ) : null
          ) : null}
        </Col>
      </Row>
      <Row style={{ flexWrap: "nowrap", height: "100%" }} align={"middle"}>
        <Col
          span={medias.length >= 3 ? 12 : 24}
          onClick={() => {
            handleTogglePreview(2);
          }}
        >
          {medias.length >= 2 ? (
            <div
              style={{
                height: "100%",
                textAlign: "center",
              }}
            >
              {medias[1].type === "image" ? (
                <img
                  style={{
                    width: "100%",
                    height: medias.length < 3 ? "150px" : "150px",
                  }}
                  src={`${medias[1].url}`}
                />
              ) : medias[1].type === "video" ? (
                <ReactPlayer
                  playing={false}
                  height={medias.length < 3 ? "250px" : "150px"}
                  width={"100%"}
                  className={"card-image-third"}
                  controls
                  light={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${medias[1].thumbnail}`}
                  onClickPreview={() => console.log("IM PREVIEW HELLO ")}
                  url={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${medias[1].compressed_video}`}
                />
              ) : null}
            </div>
          ) : null}
          <div
            className="overlay-card-items"
            onClick={() => handleTogglePreview(2)}
          />
        </Col>
        <Col
          span={medias.length >= 3 ? 12 : null}
          onClick={() => {
            handleTogglePreview(3);
          }}
        >
          {medias.length >= 3 ? (
            medias[2].type === "image" ? (
              <div className={"img-card-container "}>
                {medias?.length > 3 && (
                  <div
                    className="overlay-card-image"
                    onClick={() => handleTogglePreview()}
                  >
                    <div className="img-info">
                      <span>+ {medias?.length - 3}</span>
                    </div>
                  </div>
                )}
                <img
                  style={{
                    height: medias.length < 3 ? "250px" : "150px",
                    width: medias.length < 3 ? "521px" : "100%",
                  }}
                  className={"card-image-third"}
                  src={`${medias[2].url}`}
                />
              </div>
            ) : medias[2].type === "video" ? (
              <div className={"img-card-container "}>
                <ReactPlayer
                  playing={false}
                  height={medias.length < 3 ? "250px" : "150px"}
                  width={medias.length < 3 ? "521px" : "100%"}
                  className={"card-image-third"}
                  light={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${medias[2].thumbnail}`}
                  controls
                  url={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${medias[2].compressed_video}`}
                />
                <div
                  className="overlay-card-items"
                  onClick={() => handleTogglePreview(2)}
                />
                {medias?.length > 3 && (
                  <div
                    className="overlay-card-image"
                    onClick={() => handleTogglePreview()}
                  >
                    <div className="img-info">
                      <span>+ {medias?.length - 3}</span>
                    </div>
                  </div>
                )}
              </div>
            ) : null
          ) : null}
        </Col>
      </Row>
      <Row className={"info-card-container"} gutter={[16, 16]}>
        <Col>
          <Row className={"level-two-title"}>
            {nFormatter(post?.post_likes_total)}
          </Row>
          <Row>Likes</Row>
        </Col>
        <Col>
          <Row className={"level-two-title"}>
            {nFormatter(post?.post_comments_total)}
          </Row>
          <Row>Comments</Row>
        </Col>
        <Col>
          <Row className={"level-two-title"}>
            {nFormatter(post?.post_views_total)}
          </Row>
          <Row>Views</Row>
        </Col>
        {post.type === "feechring" && (
          <Col>
            <Row className={"level-two-title"}>
              {nFormatter(post?.feechr_up_count)}
            </Row>
            <Row>Feechr Up</Row>
          </Col>
        )}
      </Row>

      <MediaPerview
        slider={slider}
        items={medias}
        visible={visible}
        close={pausePlayer}
      />
    </div>
  );
};

export default PostCard;
