import { api, apiBackend } from "../Config/config";
api.setAccessToken(localStorage.getItem("accesstoken"));

class UserService {
  async getAllUsers(page, limit, filter) {
    try {
      console.log("LIMITTTT::", limit);

      const period = localStorage.getItem("period");
      let params = {};
      if (period && period !== ",") params.period = period;
      if (page) params.page = page;
      if (limit || limit === 0) {
        console.log("LIMITTTT::", limit);

        params.limit = limit;
      }
      if (filter) params = { ...params, ...filter };
      const { data } = await api.get(`/users/find`, { params });
      console.log(data);
      return data;
    } catch (e) {
      console.log("----sdfsdfsddf------dsfsfsd--", e);
    }
  }
  async getUserById(userId) {
    try {
      const { data } = await api.get(`/users/get/${userId}`);
      return data;
    } catch (e) {
      console.log("error get user by id", e);
    }
  }
  async fetchUserProfil(userId) {
    try {
      const { data } = await apiBackend(`/friendRequest/getUserProfile`, {
        method: "POST",
        data: { to_user_id: Number(userId) }
      });
      return data;
    } catch (e) {
      console.log("error get user by id :", e);
    }
  }

  async getTopUsers() {
    try {
      const { data } = await api.get(`/users/top-users`);
      return data;
    } catch (e) {
      console.log("error get top users", e);
    }
  }
  async updateUserStatus(user_id, status) {
    try {
      const data = await api(`/users/update_user_status`, {
        method: "PUT",
        data: {
          "user_id": user_id,
          "status": `${status}`
        }
      });
      return data;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async fetchAdmins(status, limit, page, searchTag) {
    try {
      let object = {}
      if (page) object.page = page
      if (limit) object.limit = limit
      if (searchTag) object.searchTag = searchTag
      object.status = status
      console.log("object 44444444444",object);
      const { data } = await api(`/fetchAdmins`, {
        method: "POST",
        data: object
      });
      return data;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async deleteAdmins(adminArray,token) {
    try {
      const data = await api(`/deleteAdmin`, {
        method: "DELETE",
        data: { adminArray, token }
      });
      console.log(" this is the request result :", data);
      return data;
    } catch (error) {
      console.log("error :", error);
    }
  }

  async enableAdmins(adminArray,token) {
    try {
      const data = await api(`/enableAdmin`, {
        method: "POST",
        data: { adminArray, token }
      });
      console.log(" this is the request result :", data);
      return data;
    } catch (error) {
      console.log("error :", error);
    }
  }

}
export default new UserService();
