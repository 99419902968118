import {
  Col,
  Modal,
  Row,
  Image,
  Space,
  Upload,
  message,
  Form,
  Input,
  Switch,
  Button,
} from "antd";
import {
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import CommentsCard from "../../Components/Posts/CommentsCard";
import PostCard from "../../Components/Posts/PostCard";
import PostsService from "../../Services/PostsService";
import ReactPlayer from "react-player";
import AudioService from "../../Services/AudioService";
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function beforeUploadAlbumImage(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M ? true : Upload.LIST_IGNORE;
}
function beforeUploadAudio(file) {
  const isAudio = file.type === "audio/mpeg" || file.type === "audio/x-ms-wma";
  if (!isAudio) {
    message.error("You can only upload mp3/wma file!");
  }

  return isAudio ? true : Upload.LIST_IGNORE;
}
const normFile = (e) => {
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const UpdateMusic = ({ visible, open, item }) => {
  const [form] = Form.useForm();

  const [Files, setFiles] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [
      {
        uid: "-1",
        name: "xxx.png",
        status: "done",
        url: item?.albumImage,
        thumbUrl: item?.albumImage,
        type: "image/jpeg",
      },
      {
        uid: "-1",
        name: "xxx.mp3",
        status: "done",
        url: item?.s3Url,
        thumbUrl: item?.albumImage,
        type: "audio/mpeg",
      },
    ],
  });
  function aud_play_pause() {
    var myAudio = document.getElementById("myTune");
    myAudio.pause();
  }
  const handleCancel = () => {
    Files.fileType === "audio" && aud_play_pause();
    setFiles({ ...Files, fileList: [], previewVisible: false });
  };

  const handlePreview = async (file) => {
    console.log(file.type);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setFiles({
      ...Files,
      previewImage: file.url || file.preview,
      fileType:
        file.type === "audio/mpeg" || file.type === "audio/x-ms-wma"
          ? "audio"
          : "image",
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const handleChange = (file) =>
    setFiles({ ...Files, fileList: [...Files.fileList, ...file.fileList] });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    values.id = item._id;
    if (values.audio[0].uid === "-1") delete values.audio;
    if (values.AlbumImage[0].uid === "-1") delete values.AlbumImage;
    console.log("values", values);
    //open(false);
    await AudioService.updateAudio(values);
    open(true);
  };
  const onReset = () => {
    form.resetFields();
    open(true);
  };
  console.log("IMTHE ITEM :: ", item, "FORM :", form.getFieldValue("audio"));
  useEffect(() => {
    // form.setFields({ audio: "test" });
  }, []);
  return (
    item && (
      <div>
        <Modal
          centered
          title={"Update audio"}
          visible={visible}
          onOk={() => open()}
          onCancel={() => {
            open();
            onReset();
          }}
          footer={null}
          destroyOnClose
          width={"50%"}
        >
          <Row gutter={16}>
            <Form
              form={form}
              {...layout}
              style={{ width: "100%", padding: 30 }}
              labelAlign="left"
              labelwrap
              initialValues={item}
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={"name"}
                label="Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["author"]}
                label="Author"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={"gender"}
                label="Genre"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="AlbumImage"
                label="Album Image"
                initialValue={[
                  {
                    uid: "-1",
                    name: "xxx.png",
                    status: "done",
                    url: item?.albumImage,
                    thumbUrl: item?.albumImage,
                    type: "image/jpeg",
                  },
                ]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                  },
                ]}
                // extra="longgggggggggggggggggggggggggggggggggg"
              >
                <Upload
                  name="logo"
                  className="avatar-uploader"
                  maxCount={1}
                  fileList={Files.fileList}
                  // defaultFileList={[
                  //   {
                  //     uid: "-1",
                  //     name: "xxx.png",
                  //     status: "done",
                  //     url: item?.albumImage,
                  //     thumbUrl: item?.albumImage,
                  //     type: "image/jpeg",
                  //   },
                  // ]}
                  customRequest={dummyRequest}
                  listType="picture-card"
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeUpload={beforeUploadAlbumImage}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item label="Audio files">
                <Form.Item
                  name="audio"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  noStyle
                  initialValue={[
                    {
                      uid: "-1",
                      name: item?.name,
                      status: "done",
                      url: item?.s3Url,
                      thumbUrl: item?.albumImage,
                      type: "audio/mpeg",
                    },
                  ]}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Upload.Dragger
                    name="files"
                    maxCount={1}
                    fileList={Files.fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    listType="picture"
                    customRequest={dummyRequest}
                    beforeUpload={beforeUploadAudio}
                    style={{ marginBottom: 10 }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
              <Form.Item
                name={"status"}
                label="Status"
                initialValue={item?.status ? item?.status : false}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Switch
                  checked={item?.status}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Row gutter={16} justify="center">
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      htmlType="button"
                      onClick={onReset}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Row>
          <Modal
            visible={Files.previewVisible}
            title={Files.previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            {Files.fileType === "image" ? (
              <img
                alt="example"
                style={{ width: "100%" }}
                src={Files.previewImage}
              />
            ) : (
              <audio
                style={{ width: "100%" }}
                id="myTune"
                controls
                src={Files.previewImage}
              >
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            )}
          </Modal>
        </Modal>
      </div>
    )
  );
};

export default UpdateMusic;
