import { Button, Card, Checkbox, Col, Form, Input, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { history } from "../../Helpers/history";
import { verifResetCode, resetPassword, login } from "../../redux/auth/action"; //"redux/auth/action";
import { useHistory } from "react-router-dom";
import {
    DoubleLeftOutlined
} from "@ant-design/icons";
import Logo from "./../../assets/img/Logo.png";



const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const style = {};
const ResetComponent = (props) => {
    let history = useHistory();

    const { message, email, user } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();

    const [password, setpassword] = useState("");
    const [resetCode, setresetCode] = useState("");
    const [newPassword, setnewPassword] = useState("");

    var myEmail = localStorage.getItem('email');


    const onFinish = (values) => {
        console.log('my values _________', values);
        dispatch(verifResetCode(values.email, values.ResetCode))
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (message)
            if (message == "DIGIT.VERIFIED") {
                // history.push("/authentication/reset-password");
                console.log("here useEffect : DIGIT.VERIFIED => ", message);
                dispatch(resetPassword(myEmail, newPassword))
                console.log("here second dispatch", message);
            }
        if (message == "SUCCESS.PASSWORD.RESET") {
            // history.push("/authentication/reset-password");
            console.log("here useEffect : SUCCESS.PASSWORD.RESET => ", message);

            props.login(myEmail, newPassword).then(() => {
                history.push("/");
                window.location.reload();
            });

        }
    }, [message])

    // useEffect(() => {
    //     if (user) window.location.replace("/");
    //     return () => {};
    //   }, []);





    return (

        <div style={{ backgroundColor: "#FFDDFed", paddingTop: '160px' }}>
            <img src={Logo} className="logoImg" />


            <Row gutter={[0, 0]}>
                <Col className="gutter-row" span={3}>
                    {/* <div style={style}>col-6</div> */}
                </Col>
                <Col className="gutter-row" span={18}>
                    <Row justify={"center"} align={"middle"} >
                        <Card
                            size="default"
                            title="reset password"
                            extra={<a href="/authentication/login"><DoubleLeftOutlined /> Login </a>}
                            style={{ width: "70%" }}
                        >
                            <div style={style}>
                                <Form
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 8,
                                    }}
                                    initialValues={{
                                        ["email"]: myEmail
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item name='email' label="email"
                                        rules={[{ type: 'email', message: "Please input your email!" }]}>
                                        <Input
                                            defaultValue={myEmail} />
                                    </Form.Item>

                                    <Form.Item
                                        label="ResetCode"
                                        name="ResetCode"
                                        rules={[{ required: true, message: 'Please input your resetCode!' }]}
                                    >
                                        <Input onChange={(val) => setresetCode(val.target.value)} />
                                    </Form.Item>

                                    <Form.Item
                                        label="newPassword"
                                        name="newPassword"
                                        rules={[{ required: true, message: 'Please input your password!' }]}
                                    >
                                        <Input.Password onChange={(val) => setnewPassword(val.target.value)} />
                                    </Form.Item>

                                    <Form.Item
                                        wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit" >
                                            submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Card>
                    </Row>
                </Col>

            </Row>

        </div>

    );
};

function mapStateToProps(state) {
    const { isLoggedIn, user } = state.authReducer;

    return {
        isLoggedIn,
        user,
    };
}

export default connect(mapStateToProps, { login })(ResetComponent);

