export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + " " + item.symbol
    : "0";
};
export const getRouteName = (path) => {
  switch (path) {
    case "/app/home":
      return "Overview";
    case "/app/users":
      return "User management";
    default:
      if (path.includes("/app/posts")) return "Posts management";
      if (path.includes("/app/users-profile/")) return "User management";
      if (path.includes("/app/clubs")) return "Clubs management";
      if (path.includes("/app/events")) return "Events management";
      if (path.includes("/app/hashtags")) return "Hashtags management";
      if (path.includes("/app/categories")) return "Categories management";
      if (path.includes("/app/musics")) return "Musics management";
      if (path.includes("/app/mangeReportsMotif")) return "Report motif management";
      if (path.includes("/app/reports")) return "Reports management";
      if (path.includes("/app/verification-requests")) return "Verification requests management";
      if (path.includes("/app/manage-admins")) return "Admins management";

      return "";
  }
};
