import { Avatar, Col, Dropdown, List, Menu, Row } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./previewModal.css";

const menu = (
  <Menu style={{ padding: "10px" }}>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        Delete{" "}
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        Block{" "}
      </a>
    </Menu.Item>
    <Menu.Item onClick={() => copyToClipboard()}>Copy Post URL </Menu.Item>
  </Menu>
);

const copyToClipboard = () => {
  let textField = document.createElement("textarea");
  textField.innerText = "foo bar baz";
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};
const CommentsComponent = ({ item, index }) => {
  const [selectedComment, setSelectedComment] = useState(0);
  console.log("dfklglkdfjgldf", item);
  return (
    <div key={index} onClick={() => setSelectedComment(index)}>
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col span={3}>
          <Avatar
            src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${item.user?.profile_image}`}
          />
        </Col>
        <Col span={16}>
          <Row
            style={{
              backgroundColor: "yellow",
              borderRadius: "50px",
              padding: "5px",
              paddingRight: "20px",
              paddingLeft: "20px",
            }}
          >
            {item.comment}
          </Row>
          <Row gutter={16}>
            <Col>{dayjs(item.createdAt).fromNow()}</Col>
            <Col>Like ({item.comments_likes_total})</Col>
          </Row>
        </Col>
        <Col>...</Col>
      </Row>
      {item?.comments_comments?.length > 0 &&
        item?.comments_comments.map((subComment, index) => (
          <div style={{ marginLeft: "30px" }}>
            <CommentsComponent key={index} item={subComment} />{" "}
          </div>
        ))}
    </div>
  );
};

const CommentsCard = ({ post }) => {
  const history = useHistory();

  dayjs.extend(relativeTime);
  const [visible, setVisible] = useState(false);
  console.log(post, "IM HERRREEE EFFES");
  useEffect(() => {
    return () => { };
  }, [post]);
  return (
    post && (
      <div>
        <Row justify={"space-between"}>
          <Col>
            <Row gutter={16}>
              <Col>
                <Avatar
                  size={40}
                  src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${post.owner.profile_image}`}
                />
              </Col>
              <Col>
                <Row className={"level-two-title"}>
                  <a
                    onClick={() => {
                      history.push(`users-profile/${post.owner.user_id}`);
                    }}
                  >
                    {post.owner.fullName}
                  </a>
                </Row>

                <Row>{dayjs(post.createdAt).fromNow()}</Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Dropdown overlay={menu} placement="bottomRight" arrow>
                <div>...</div>
              </Dropdown>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: 200 }}>{post.description}</Row>

        <Row className={"level-two-title"}>Comments</Row>

        <Row
          style={{
            overflowY: "scroll",
            height: "100%",
            maxHeight: "450px",
            overflowX: "hidden",
          }}
        >
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={post.post_comments}
              renderItem={(item, index) => (
                <CommentsComponent item={item} index={index} key={index} />
              )}
            />
          </Col>
        </Row>
      </div>
    )
  );
};

export default CommentsCard;
