/********Authentication*********/
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const FORGET_PASSWORD_EMAIL_SENT = "FORGET_PASSWORD_EMAIL_SENT";
export const FORGET_PASSWORD_EMAIL_FAILED = "FORGET_PASSWORD_EMAIL_FAILED";
export const SUCCESS_PASSWORD_RESET = "SUCCESS_PASSWORD_RESET";
export const DIGIT_VERIFIED = "DIGIT_VERIFIED";
export const FAIL_EMAIL = "FAIL_EMAIL";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL"
export const LOG_OUT = "LOG_OUT";
export const WRONG_CREDENTIALS = "WRONG_CREDENTIALS"
export const API_FORBIDDEN = "API_FORBIDDEN"
/**************Users***************/
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const SET_PERIOD = "SET_PERIOD";
/**************Posts***************/
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAIL = "FETCH_POSTS_FAIL";
