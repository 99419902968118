import { Col, Modal, Row, Image, Space, Tag, Button, Divider, Form } from "antd";
import React, { useEffect, useState } from "react";
import randomColor from "randomcolor";
import VerificationRequestServices from "../../Services/VerificationAccount";

const ClubDetails = ({ visible, open, item }) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMotif, setSelectedMotif] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div>
      <Modal
        centered
        title={"Category Details"}
        visible={visible}
        onOk={() => open(null)}
        onCancel={() => open(null)}
        footer={null}
        width={"60%"}
      >
        <Row gutter={16}>
          <Col span={12}>
            {item.selectedImage == "image" ? <Image src={process.env.REACT_APP_BASE_S3_VIDEO_URL + item.clubImage} /> : <Image src={process.env.REACT_APP_BASE_S3_VIDEO_URL + item.clubCoverImage} />}
          </Col>
          <Col span={12}>
            <Space
              style={{
                padding: 20,
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
              }}
              direction="vertical"
            >
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Club name
                </Col>
                <Col xs={12}>{item?.clubName}</Col>
              </Row>

              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Total members
                </Col>
                <Col xs={12}>
                  {item?.members_count}
                </Col>
              </Row>

              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Total comments
                </Col>
                <Col xs={12}>
                  {item?.members_count}
                </Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                Total likes
                </Col>
                <Col xs={12}>
                  {item?.likes_counter_total}
                </Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                Total views
                </Col>
                <Col xs={12}>
                  {item?.vues_counter_total}
                </Col>
              </Row>

            </Space>
          </Col>
          <Divider />
        </Row>
      </Modal>
    </div>
  );
};

export default ClubDetails;
