import React from "react";
import { Col, Row } from "antd";
import "./card_stat.css";
import { nFormatter } from "../../../Helpers/utils";
const CardStat = ({ counter, title, description, xs, sm, md, lg, xl }) => {
  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={{ padding: "10px" }}
      className={"card-stat"}
    >
      <div className={"card_container"}>
        <Row className={"title"}>{nFormatter(counter, 1)}</Row>
        <Row className={"second-title"}>{title}</Row>
      </div>
    </Col>
  );
};

export default CardStat;
