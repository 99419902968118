import React, { useState } from "react";
import { Pagination, Row, Table, Tag, Modal } from "antd";
import UpdateMotif from "../../../Screens/Reports/ReportMotifs.js/UpdateMotif";
import {
  DeleteOutlined,
} from "@ant-design/icons";
import reportsService from "../../../Services/ReportsService";

export default function ReportMotifsTable({
  motifs,
  total,
  currentLimit,
  currentPage,
  onLimit,
  onPage,
  updateVisible,
  handleOpenUpdateModal,
}) {
  const [selectionType, setSelectionType] = useState("radio");
  const [selectedMotif, setSelectedMotif] = useState(null);
  const [motif, setMotif] = useState();
  const [showAudioDetails, setShowAudioDetails] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      selectedRows?.length > 0 && setSelectedMotif(selectedRows[0]);
    },
    getCheckboxProps: (record) => ({
      //disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const report_motifs_columns = [
    {
      title: "Title",
      dataIndex: "categoryName",
      render: (text, motif) => (
        <a
          style={
            {
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center",
              // justifyContent: "center",
            }
          }
          onClick={() => {
            //handleOpenAudioDetails(category);
          }}
        >
          {motif?.tag_type?.en}
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, motif) =>
        motif?.active ? (
          <Tag color="default">Enabled</Tag>
        ) : (
          <Tag color="default">Disabled</Tag>
        ),
    },
    {
      title: "remove",
      render: (text, motif) =>
        <DeleteOutlined onClick={() => showModal(motif)}>
          Delete Motif
        </DeleteOutlined>
    },
  ];

  const showModal = (motif) => {
    setMotif(motif)
    setIsModalVisible(true);
  };

  const handleOk = (e) => {
    console.log("motif deleted", e);
    reportsService.deleteReportMotif(e._id).then(()=>{
      window.location.reload()
    })
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <div>
      <Modal title="Delete Motif" visible={isModalVisible} onOk={() => handleOk(motif)} onCancel={handleCancel}>
        Would you delete this motif ?
      </Modal>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div
                style={{
                  justifyContent: "space-around",
                }}
              >
                {Object.keys(record?.tag_type).map((x, i) => (
                  <p style={{ margin: 5 }} key={i}>
                    <Tag color="volcano">{x}</Tag>: {record?.tag_type[x]}
                  </p>
                ))}
              </div>
            );
          },
          //rowExpandable: (record) => record?.name !== "Not Expandable",
        }}
        columns={report_motifs_columns}
        dataSource={motifs.map((x, i) => {
          return { key: i, ...x };
        })}
        pagination={false}
      />
      <Row justify={"end"} style={{ marginTop: "20px" }}>
        <Pagination
          className={"pagination"}
          defaultCurrent={1}
          current={Number(currentPage)}
          total={total}
          onChange={(page, pageSize) => {
            if (page !== currentPage) onPage(page);
            if (pageSize !== currentLimit) onLimit(pageSize);
          }}
        />
      </Row>
      <UpdateMotif
        visible={updateVisible}
        open={handleOpenUpdateModal}
        item={selectedMotif}
      />
    </div>
  );
}
