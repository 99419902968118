import { Col, Modal, Row, Divider, Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import CommentsCard from "../../Components/Posts/CommentsCard";
import PostCard from "../../Components/Posts/PostCard";
import PostsService from "../../Services/PostsService";

const PostDetails = ({ title, visible, open, item }) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [postsId, setPostsId] = useState("");
  const [status, setStatus] = useState("");
  const [token, setToken] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [select, setSelect] = useState("");



  const fetch_post_by_id = (id) => {
    PostsService.getPostById(id)
      .then((data) => {
        setPost(data);
        setIsLoading(false);
      })
      .catch((e) => setError(e));
  };
  useEffect(() => {
    item && fetch_post_by_id(item._id);
    return () => {
      setIsLoading(true);
    };
  }, [item]);

  const showModal = (e) => {
    setSelect(e)
    setIsModalVisible(true);
  };

  const handleOk = (e) => {
    let myStatus;
    (e.active == true) ? myStatus = false : myStatus = true;
    let myToken = localStorage.getItem("accesstoken");

    PostsService.enableAdmins(item._id, myStatus, myToken).then(() => {
      window.location.reload();
    })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return isLoading ? (
    <div>isLoading dd</div>
  ) : error ? (
    <div>error</div>
  ) : (
    <div>
       <Modal title="Basic Modal" visible={isModalVisible} onOk={() => handleOk(item)} onCancel={handleCancel}>
       would you { select == "block" ? "block" : "enable" } the following selected post ?
      </Modal>
      <Modal
        centered
        title={title ? title : "Post Details"}
        visible={visible}
        onOk={() => open(null)}
        onCancel={() => open(null)}
        footer={null}
        width={"60%"}
      >
        <Row gutter={16}>
          <Col span={12}>
            <PostCard post={item} />
          </Col>
          <Col span={12}>
            <CommentsCard post={post} />
          </Col>
          <Divider />
    <div style={{width:"100%"}}>
          <Row gutter={16} justify="center">
            {post.active == true ? <Button
              style={{ width: 200 }}
              type="primary"
              onClick={() => showModal("block")}
            >
              Block post
            </Button> : <Button style={{ width: 200 }}
              type="primary"
              onClick={() => showModal("enable")}
            >
              Enable post
            </Button>}
          </Row>
    </div>

        </Row>
      </Modal>
    </div>
  );
};

export default PostDetails;
