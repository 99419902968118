import { api } from "../Config/config";

api.setAccessToken(localStorage.getItem("accesstoken"));

class PostsService {
  async getPostes(userId, filter) {
    //(page, limit, searchTxt, userId, filter)
    try {
      console.log("TEXT SEARCH :: ", filter.searchTxt);
      const period = localStorage.getItem("period");
      let params = {};
      if (period !== ",") params.period = period;
      // if (filter.page) params.page = page;
      // if (filter.limit) params.limit = limit;
      // if (searchTxt) params.searchTxt = searchTxt;
      if (userId) params["owner.user_id"] = userId;
      if (filter) params = { ...params, ...filter };
      console.log(params, "ALL PARAMS ARE HERE ");

      const { data } = await api.get(`/posts/find`, { params });
      return data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async getPostById(id) {
    try {
      const { data } = await api.get(`/post-comments/${id}`);
      return data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async getTopPosts() {
    try {
      const { data } = await api.get(`/top-posts`);
      return data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async getPostsMonthsViews() {
    try {
      const { data } = await api.get(`/fetch-views-posts`);
      return data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async getOverView() {
    try {
      const { data } = await api.get(`/overview`);
      return data.data;
    } catch (e) {
      console.log(e);
    }
  }
  async enableAdmins(postsId,status,token) {
    try {
      const data = await api(`/updatePostStatus`, {
        method: "POST",
        data: { postsId, status },
        headers: {
          Authorization: `token ${token}`,
        },
      });
      console.log(" this is the request result :", data);
      return data;
    } catch (error) {
      console.log("error :", error);
    }
  }

}

export default new PostsService();
