import axios from "axios";
import { api, apiBackend } from "../Config/config";

class AuthService {
  async login(email, password) {
    return await api
      .post(`/authentification`, {
        email: email,
        password: password,
      })
      .then(({ data }) => {
        localStorage.setItem("accesstoken", data.data.accessToken);
        return data.data;
      })
      .catch((error) => {
        console.log("55555555555",error);
        return error.response;
      });
  }
  async verifyToken(token) {
    const user = await api.post(`/verifytoken`, {
      token: token,
    });
    console.log("verified token :: ", user.data);
    localStorage.setItem("role", user.data.data.role);
    return user.data.data;
  }
  async forgotPwdService(data) {
    const forget = await apiBackend("user/forgetPasswordMail", {
      method: "POST",
      data,
    })
    const object = {
      isLoading: true,
      user: null,
      message: forget.data.message
    }
    return object
  };
  verifResetPasswordCodeService = (code, email) => {
    apiBackend.post("/verifResetPasswordMail/" + code, { email: email });
  };
  resetPwdService = (token, password) => {
    apiBackend.post("/resetPassword/", { password: password });
  };
  logout = () => {
    localStorage.removeItem("accesstoken");
    window.location.reload();
  };
}

export default new AuthService();
