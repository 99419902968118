import { api } from "../Config/config";
api.setAccessToken(localStorage.getItem("accesstoken"));

class ReportsService {
  async getAllReports(page, limit, filter) {
    try {
      const period = localStorage.getItem("period");
      console.log("FILTER MUISIC FROM SERVICE ", filter, period);
      let params = {};
      if (period && period !== ",") params.period = period;
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (filter) params = { ...params, ...filter };
      const { data } = await api.get(`/reports`, { params });
      console.log(data);
      return data;
    } catch (e) {
      console.log("----sdfsdfsddf------dsfsfsd--", e);
    }
  }
  /**************REPORT MOTIFS ************************/
  async getAllReportMotifs(page, limit, filter) {
    try {
      const period = localStorage.getItem("period");
      console.log("FILTER MUISIC FROM SERVICE ", filter, period);
      let params = {};
      if (period && period !== ",") params.period = period;
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (filter) params = { ...params, ...filter };
      const { data } = await api.get(`/reports/motifs`, { params });
      console.log(data);
      return data;
    } catch (e) {
      console.log("----sdfsdfsddf------dsfsfsd--", e);
    }
  }
  async insertReportMotif(motif) {
    try {
      console.log(motif, "REPORT MOTIFS ");

      const { data } = await api.post(`/reports/motifs`, motif);
      console.log(data);
      return data;
    } catch (e) {
      console.log("error while inserting a new motif", e);
    }
  }
  async updateReportMotif(motif) {
    try {
      const { data } = await api.put(`/reports/motifs`, motif);
      return data;
    } catch (e) {
      console.log("error while updating a report motif", e);
    }
  }

  async deleteReportMotif(_id) {
    try {
        const data = await api(`/reports/motifs/delete`, {
            method: "DELETE",
            data: { _id }
        });
        console.log(" this is the request result :", data);
        return data;
    } catch (error) {
        console.log("error :", error);
    }
}
}
export default new ReportsService();
