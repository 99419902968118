import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import ReportTable from "../../Components/Reports/Motifs/ReportsTable";
import ReportsService from "../../Services/ReportsService";
import SendNotif from "../Notifications/SendNotif";
export default function Reports() {
  const history = useHistory();
  const [reportsList, setReportsList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState(null);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(null);
  const [openNotifModal, setOpenNotifModal] = useState(false);

  const fetch_reports = (page, limit, filter) => {
    setError(false);
    setIsLoading(true);
    ReportsService.getAllReports(page, limit, filter)
      .then(({ data }) => {
        console.log("Reports DATA :: ", data);
        setReportsList(data.data);
        setTotalItems(data.attrs?.total || 0);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  };

  const handlePageChange = useCallback(
    (page) => {
      console.log("HELLO TEST FILTER FROM PAGE");
      setPage(page);
      setFilter({ ...filter, page });
    },
    [page, filter]
  );

  const handleLimitChange = useCallback(
    (limit) => {
      console.log("HELLO TEST FILTER FROM LIMIT");
      setLimit(limit);
      setFilter({ ...filter, limit });
    },
    [limit]
  );
  const handleSearch = useCallback(
    (text) => {
      console.log("HELLO TEST FILTER FROM SERACH", text.target.value);

      if (text.target.value.length > 0) {
        console.log("there is a text");
        setSearchTxt(text.target.value);
        setPage(1);
        setFilter({ ...filter, searchTxt: text.target.value, page: 1 });
      } else {
        console.log("there is no text");

        setSearchTxt(null);
        let newFilter = filter;
        delete newFilter.searchTxt;
        newFilter.page = 1;
        console.log("there is no text", newFilter);

        setFilter(newFilter);
      }
    },
    [searchTxt]
  );

  useEffect(() => {
    console.log("HANDLE CHANGE ");
    fetch_reports(page, limit, filter);
    return () => { };
  }, [filter, searchTxt]);
  return (
    <div>
      <Row
        style={{ marginBottom: "20px" }}
        align={"middle"}
        justify={"space-between"}
      >
        {/* <Button onClick={() => setOpenNotifModal(!openNotifModal)}>
          Send Notification
        </Button> */}
        <div >
        <Button  onClick={() => history.push("/app/mangeReportsMotif")}>
          Manage Reports models
        </Button>
        </div>
        <Col >
          <Row align={"middle"} justify={"space-between"}>
            {/* <Col>

            </Col>
            <Col>

            </Col> */}
            <Col >
              <Input
                style={{
                  borderRadius: "20px",
                  width: "150px",
                }}
                size="default"
                placeholder="Search"
                onChange={handleSearch}
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportTable
        reports={reportsList}
        total={totalItems}
        currentPage={page}
        currentLimit={limit}
        onPage={handlePageChange}
        onLimit={handleLimitChange}
      />
      <SendNotif
        visible={openNotifModal}
        open={() => setOpenNotifModal(!openNotifModal)}
      />
    </div>
  );
}
