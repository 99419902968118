import { Button, Card, Checkbox, Col, Form, Input, Layout, Row, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { history } from "../../Helpers/history";
import { login } from "../../redux/auth/action"; //"redux/auth/action";
import {
  ArrowRightOutlined,
  DoubleRightOutlined
} from "@ant-design/icons";
import Logo from "./../../assets/img/Logo.png";


const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const style = {};
const LoginComponent = (props) => {
  const { user, message } = useSelector((state) => state.authReducer);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const login = async (email, password) => {
    //console.log("hello");
    let logged = await login(email, password);
    console.log(logged);
    return logged.status === 403 ? window.alert(logged.data) : null;
  };
  const onFinish = (values) => {
    props.login(email, password).then(() => {
      history.push("/");
      window.location.reload();
    });
    // if (message == "API_CREDENTIAL_INCORRECT") {
    //   alert('Credentials not valid');
    // }
    // if (message == "API_PASSWORD_INCORRECT") {
    //   alert('password not balid');
    // }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
      if (message == "API_CREDENTIAL_INCORRECT" ) {
        alert('Credentials not valid');
      }
      if (message == "API_PASSWORD_INCORRECT") {
        alert('Credentials not valid');
      }
      if (message == "API_FORBIDDEN"){
        alert('Access denided');
      }
  }, [message])


  useEffect(() => {
    if (user) window.location.replace("/");
    return () => { };
  }, []);
  return (
    <div style={{ backgroundColor: "#FFDDFed", paddingTop:'160px' }}>
            <img src={Logo} className="logoImg"/>

      <Row gutter={[0, 0]}>
        <Col className="gutter-row" span={3}>
        </Col>
        <Col className="gutter-row" span={18}>
          <Row justify={"center"} align={"middle"}>
            <Card
              size="default"
              title="Login"

              extra={<Button type="link" htmlType="submit">
                <a href="/authentication/create-account"><DoubleRightOutlined /> Create account</a>
              </Button>}
              style={{ width: "70%" }}
            >
              <div style={style}>
                <Form
                  {...layout}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Login"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input
                      value={email}
                      onChange={(val) => setemail(val.target.value)}
                      placeholder={"please insert email"}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={"please insert password"}
                      value={password}
                      onChange={(val) => setpassword(val.target.value)}
                    />
                  </Form.Item>
                  <Form.Item >
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>

                  </Form.Item>
                  <Checkbox>Remember me</Checkbox>
                </Form>
                <Divider />
                <Button type="link" htmlType="submit">
                  {<a href="/authentication/forget-password"><DoubleRightOutlined /> forgot password</a>}
                </Button>

              </div>
            </Card>

          </Row>
        </Col>
        <Col className="gutter-row" span={3}>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { isLoggedIn, user } = state.authReducer;

  return {
    isLoggedIn,
    user,
  };
}

export default connect(mapStateToProps, { login })(LoginComponent);
