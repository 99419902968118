import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { posts_filters } from "../../Commun/constant";
import FilterButonGroup from "../../Components/FilterButton/FilterButonGroup";
import PostsTable from "../../Components/Posts/postsTable";
import { fetch_posts } from "../../redux/posts/action";
import { CSVDownloader } from "react-papaparse";
const Posts = () => {
  const { isLoading, postsList, total_posts, error } = useSelector(
    (state) => state.postsReducer
  );

  const [page, setPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState(null);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(null);
  const [testfilter, settestFilter] = useState({});

  const dispatch = useDispatch();

  const handleSearch = useCallback(
    (text) => {
      console.log("HELLO TEST FILTER FROM SERACH");

      if (text.target.value) {
        setSearchTxt(text.target.value);
        setPage(1);
        settestFilter({ ...testfilter, searchTxt: text.target.value, page: 1 });
      } else {
        setSearchTxt(null);
        let newFilter = testfilter;
        delete newFilter.searchTxt;
        newFilter.page = 1;
        settestFilter(newFilter);
      }
    },
    [searchTxt, testfilter]
  );
  const handlePageChange = useCallback(
    (page) => {
      console.log("HELLO TEST FILTER FROM PAGE");
      setPage(page);
      settestFilter({ ...testfilter, page: page });
    },
    [page, testfilter]
  );
  const handleLimitChange = useCallback(
    (limit) => {
      console.log("HELLO TEST FILTER FROM LIMIT");

      setLimit(limit);
      settestFilter({ ...testfilter, limit: limit });
    },
    [limit, testfilter]
  );
  const handleFilterChange = useCallback(
    (filter2) => {
      console.log("HELLO TEST FILTER FROM FILTER rrr", filter2);
      let oldFilters = testfilter;
      delete oldFilters.type;
      delete oldFilters.post_parent_id;

      setFilter(filter2);
      setPage(1);
      settestFilter({ ...oldFilters, ...filter2 });
    },
    [filter, testfilter]
  );

  useEffect(() => {
    console.log("HELLO TEST FILTER", testfilter);
    //fetch_posts Parmas : page-limit-searchTxt-userId-filter
    //dispatch(fetch_posts(page, limit, searchTxt, null, filter));
    dispatch(fetch_posts(null, testfilter));
    return () => {};
  }, [testfilter]);

  return (
    <div>
      <Row
        style={{ marginBottom: "20px" }}
        // align={"middle"}
        justify={"space-between"}
      >
        <Col>
          <FilterButonGroup
            filters={posts_filters}
            onFilter={handleFilterChange}
          />
        </Col>
        <Col>
          {/* <Row className={"btn-group"}>
            <CSVDownloader
              data={postsList.map((x) => {
                return {
                  Owner: x.owner.fullName,
                  images: x.medias.images.map((x) => x.url),
                  videos: x.medias.videos.map((x) => x.url),
                  views: x.post_views_total,
                  likes: x.post_likes_total,
                  comments: x.post_comments_total,
                  date: x.createdAt,
                };
              })}
              type="button"
              filename={"filename"}
              bom={true}
              style={{ borderRadius: 20, width: 150, height: 30 }}
              className={["selected-btn"]}
            >
              Download
            </CSVDownloader>
          </Row> */}
        </Col>
        <Col>
          <Input
            style={{
              borderRadius: "20px",
              width: "150px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
            size="default"
            placeholder="Search"
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>
      {error ? (
        <div>error</div>
      ) : isLoading ? (
        <Skeleton />
      ) : (
        <PostsTable
          data={postsList}
          total={total_posts}
          currentPage={page}
          currentLimit={limit}
          onPage={handlePageChange}
          onLimit={handleLimitChange}
        />
      )}
    </div>
  );
};

export default Posts;
