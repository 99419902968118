import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import overview from "../Screens/overview";
import posts from "../Screens/posts/posts";
import UserProfile from "../Screens/users/userProfile";
import users from "../Screens/users/users";
import Musics from "../Screens/musics/Musics";
import Category from "../Screens/category/Categories";
import Reports from "../Screens/Reports/Reports";
import ReportMotifs from "../Screens/Reports/ReportMotifs.js/ReportMotifs";
import Verification from "../Screens/verification/verificationRequests";
import Events from "../Screens/events/events";
import HashTags from "../Screens/hashTags/hashTags";
import Clubs from "../Screens/clubs/clubs"
import ManageAdmins from "../Screens/admins/admins"

import MainLayout from "./Layouts/MainLayout";

const MainRoute = ({ match }) => {
  console.log(match);
  return (
    <MainLayout>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
        <Route path={`${match.url}/home`} component={overview} />
        <Route path={`${match.url}/users`} component={users} />
        <Route
          path={`${match.url}/users-profile/:id`}
          component={UserProfile}
        />
        <Route path={`${match.url}/posts`} component={posts} />
        <Route path={`${match.url}/musics`} component={Musics} />
        <Route path={`${match.url}/hashtags`} component={HashTags} />
        <Route path={`${match.url}/categories`} component={Category} />
        <Route path={`${match.url}/reports`} component={Reports} />
        <Route path={`${match.url}/verification-requests`} component={Verification} />
        <Route path={`${match.url}/events`} component={Events} />
        <Route path={`${match.url}/clubs`} component={Clubs} />
        <Route path={`${match.url}/manage-admins`} component={ManageAdmins} />
        
        
        <Route
          path={`${match.url}/mangeReportsMotif`}
          component={ReportMotifs}
        />
        <Redirect to="/error" />
      </Switch>
    </MainLayout>
  );
};

export default MainRoute;
