import React, { useState, useEffect, useCallback } from "react";
import CategoryTable from "../../Components/Categories/CategoryTable";
import CategoryService from "../../Services/CategoryService";
import { SearchOutlined } from "@ant-design/icons";
import { Skeleton, Input, Col, Row, Button } from "antd";
import { category_actions } from "../../Commun/constant";
import AddCategory from "./AddCategory";
import { useSelector } from "react-redux";
export default function Category() {
  const { period } = useSelector((state) => state.usersReducer);
  const [audioList, setAudioList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [addCategoryModal, setCategoryModal] = useState(false);
  const [updateMusicModal, setUpdateMusicModal] = useState(false);

  const [page, setPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState(null);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(null);

  const fetch_categories = (page, limit, filter) => {
    setError(false);
    setIsLoading(true);

    CategoryService.getAllCategories(page, limit, filter)
      .then((data) => {
        console.log("AUDIO DATA :: ", data);
        setAudioList(data.data);
        setTotalItems(data.attrs?.total || 0);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  };

  const handleOpenAddModal = useCallback(
    (status) => {
      setCategoryModal(!addCategoryModal);
      if (status) fetch_categories(1, limit, filter);
    },
    [addCategoryModal]
  );

  const handleOpenUpdateModal = useCallback(
    (status) => {
      setUpdateMusicModal(!updateMusicModal);
      if (status) fetch_categories(1, limit, filter);
    },
    [updateMusicModal]
  );

  const handleActions = useCallback((action) => {
    // setAddMusicModal(!addMusicModal);
    switch (action) {
      case "Add Category": {
        setCategoryModal(!addCategoryModal);
        return;
      }
      case "Update Category": {
        setUpdateMusicModal(!updateMusicModal);
        return;
      }
    }
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      console.log("HELLO TEST FILTER FROM PAGE");
      setPage(page);
      setFilter({ ...filter, page });
    },
    [page, filter]
  );

  const handleLimitChange = useCallback(
    (limit) => {
      console.log("HELLO TEST FILTER FROM LIMIT");
      setLimit(limit);
      setFilter({ ...filter, limit });
    },
    [limit]
  );
  const handleSearch = useCallback(
    (text) => {
      console.log("HELLO TEST FILTER FROM SERACH", text.target.value);

      if (text.target.value.length > 0) {
        console.log("there is a text");
        setSearchTxt(text.target.value);
        setPage(1);
        setFilter({ ...filter, searchTxt: text.target.value, page: 1 });
      } else {
        console.log("there is no text");

        setSearchTxt(null);
        let newFilter = filter;
        delete newFilter.searchTxt;
        newFilter.page = 1;
        console.log("there is no text", newFilter);

        setFilter(newFilter);
      }
    },
    [searchTxt]
  );
  console.log("IM MUSIC FILTER :: ", filter);
  useEffect(() => {
    fetch_categories(page, limit, filter);
  }, [period]);
  useEffect(() => {
    console.log("HANDLE CHANGE ");
    fetch_categories(page, limit, filter);
    return () => {};
  }, [filter, searchTxt]);
  return (
    <div>
      <Row
        style={{ marginBottom: "20px" }}
        align={"middle"}
        justify={"space-between"}
      >
        <Col>
          <ActionButtonGroup
            actions={category_actions}
            handleSelectedAction={handleActions}
          />
        </Col>
        <Col>
          <Input
            style={{
              borderRadius: "20px",
              width: "150px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
            size="default"
            placeholder="Search"
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>
      {error ? (
        <div>error</div>
      ) : isLoading ? (
        <Skeleton />
      ) : (
        <CategoryTable
          data={audioList}
          total={totalItems}
          currentPage={page}
          currentLimit={limit}
          onPage={handlePageChange}
          onLimit={handleLimitChange}
          updateVisible={updateMusicModal}
          handleOpenUpdateModal={handleOpenUpdateModal}
        />
      )}
      <AddCategory visible={addCategoryModal} open={handleOpenAddModal} />
    </div>
  );
}
const ActionButtonGroup = ({ actions, items, handleSelectedAction }) => {
  const [selectedAction, setSelectedAction] = useState(-1);
  const [options, setOptions] = useState(items);
  const handleActionChange = (index, action) => {
    setSelectedAction(index);
    handleSelectedAction(action);
  };
  useEffect(() => {
    setOptions(items);
    return () => {};
  }, [items]);
  return (
    <Row className={"btn-group"}>
      {actions.map((x, index) => (
        <Button
          onClick={() => handleActionChange(index, x)}
          className={"selected-btn "}
          key={index}
        >
          {x}
        </Button>
      ))}
    </Row>
  );
};
