import { Modal, Input, Row, Col, Form, Button, Switch } from "antd";
import React, { useState } from "react";
import Select from "react-select";
import {
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import SearchSelect from "../../Commun/SearchSelect";
import useFetch from "../../Commun/useFetch";
import NotificationsService from "../../Services/NotificationsService";
const { TextArea } = Input;
const { Option } = Select;
export default function SendNotif({ visible, open }) {
  //const { data: quote, loading, error, fetchMore } = useFetch();
  const [form] = Form.useForm();

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }
  const onFinish = (values) => {
    console.log(values);
    NotificationsService.create_new_notification(values).then((data) =>
      console.log("CREATE NOTIF: ", data)
    );
    open();
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <Modal
      centered
      title={"Send notification"}
      visible={visible}
      onOk={() => open()}
      onCancel={() => {
        open();
        onReset();
      }}
      footer={null}
      destroyOnClose
      width={"50%"}
    >
      <Form
        form={form}
        style={{ width: "100%", padding: 30 }}
        labelAlign="left"
        name="nest-messages"
        onFinish={onFinish}
        // validateMessages={validateMessages}
      >
        <Row gutter={[16, 16]}>
          <Col>
            <Form.Item
              name={"broadcast"}
              label="Send to all"
              initialValue={false}
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"users"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <SearchSelect
                style={{ width: "100%" }}
                placeholder={"select users"}
                size={"large"}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={24}>
            {/* LOADING :: {loading} DATA ::{quote?.toString()}
          ERROR :: {error} */}
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={16} justify="center">
            <Col>
              <Button style={{ width: 200 }} type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
            <Col>
              <Button
                style={{ width: 200 }}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}
