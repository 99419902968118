import React, { useState, useCallback } from "react";
import { Pagination, Row, Table, Tag, Modal } from "antd";
import MusicDetails from "../../Screens/musics/MusicDetails";
import UpdateMusic from "../../Screens/musics/UpdateMusic";
import AudioService from "../../Services/AudioService";
import {
  DeleteOutlined,
} from "@ant-design/icons";


const size = 40;
export default function MusicTable({
  data,
  total,
  currentLimit,
  currentPage,
  onLimit,
  onPage,
  updateVisible,
  handleOpenUpdateModal,
}) {
  const [selectionType, setSelectionType] = useState("radio");
  const [showAudioDetails, setShowAudioDetails] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [music, setMusic] = useState();
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      selectedRows?.length > 0 && setSelectedAudio(selectedRows[0]);
    },
    getCheckboxProps: (record) => ({
      //disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const audio_columns = [
    {
      title: "",
      dataIndex: "medias",
      render: (media) =>
        // media?.images.length > 0 ? (
        //   <PictureOutlined style={{ fontSize: "20px" }} />
        // ) : media?.videos.length > 0 ? (
        //   <YoutubeFilled style={{ fontSize: "20px" }} />
        // ) :
        null,
    },
    {
      title: "Title",
      dataIndex: "name",
      render: (text, audio) => (
        <a
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            handleOpenAudioDetails(audio);
          }}
        >
          <div>
            <img
              style={{
                width: "60px",
                height: "55px",
                borderRadius: "12px",
                marginRight: "8px",
              }}
              src={audio.albumImage}
            />
          </div>
          <div style={{ maxWidth: "450px" }}>
            <p>{audio.name}</p>
          </div>
        </a>
      ),
    },
    {
      title: "Author",
      dataIndex: "author",
      responsive: ["md"],
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Count",
      dataIndex: "usedCount",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, audio) =>
        audio.status ? (
          <Tag color="default">Enabled</Tag>
        ) : (
          <Tag color="default">Disabled</Tag>
        ),
    },
    {
      title: "remove",
      render: (text, music) =>
        <DeleteOutlined onClick={() => showModal(music)}>
          Delete Category
        </DeleteOutlined>
    },
  ];

  const showModal = (music) => {
    setMusic(music)
    setIsModalVisible(true);
  };

  const handleOk = (e) => {
    AudioService.deleteAudio(e._id).then(()=>{
      window.location.reload()
    })
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const handleOpenAudioDetails = useCallback(
    (audio) => {
      setShowAudioDetails(!showAudioDetails);
      !showAudioDetails === true
        ? setSelectedAudio(audio)
        : setSelectedAudio(false);
    },
    [showAudioDetails]
  );
  return (
    <div>
       <Modal title="Delete Music" visible={isModalVisible} onOk={() => handleOk(music)} onCancel={handleCancel}>
        Would you delete this music ?
      </Modal>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={audio_columns}
        dataSource={data?.map((x, i) => ({ ...x, key: i }))}
        pagination={false}
      />
      <Row justify={"end"} style={{ marginTop: "20px" }}>
        <Pagination
          className={"pagination"}
          defaultCurrent={1}
          current={Number(currentPage)}
          total={total}
          onChange={(page, pageSize) => {
            if (page !== currentPage) onPage(page);
            if (pageSize !== currentLimit) onLimit(pageSize);
          }}
        />
      </Row>

      {showAudioDetails && (
        <MusicDetails
          visible={showAudioDetails}
          open={handleOpenAudioDetails}
          item={selectedAudio}
        />
      )}
      <UpdateMusic
        visible={updateVisible}
        open={handleOpenUpdateModal}
        item={selectedAudio}
      />
    </div>
  );
}
