import React, { useState } from "react";
import { Form, Input, Select, Col, Row } from "antd";
import DebounceSelect from "./DebounceSelect";
import world from "../../assets/constants/world.json";

const { Option } = Select;

const LanguageComponent = ({ value = {}, onChange }) => {
  const [word, setWord] = useState("");
  //const [currency, setCurrency] = useState("rmb");
  const [country, setCountry] = useState([]);
  console.log(value);
  const triggerChange = (changedValue) => {
    console.log(changedValue);
    onChange?.({
      word,
      ...value,
      ...changedValue,
    });
  };

  const onWordChange = (e) => {
    triggerChange({
      word: e.target.value,
    });
  };
  const onCountryChange = (lang) => {
    console.log("IM VALUE::", lang);
    triggerChange({
      language: lang,
    });
  };

  return (
    <Row
      style={{
        width: "100%",
      }}
      justify="space-between"
      gutter={16}
    >
      <Col
        span={8}
        style={{ width: "100%", margin: 0, paddingLeft: 0, paddingRight: 0 }}
      >
        {/* <DebounceSelect
          //mode="multiple"
          value={country}
          placeholder="select language"
          onChange={onCountryChange}
          // style={{ width: "100%", margin: 0, paddingLeft: 0, paddingRight: 0 }}
        /> */}
        <Select
          showSearch
          onChange={onCountryChange}
          onSearch={() => console.log("HELLOO")}
          filterOption={true}
          options={world.map((country) => ({
            label: `${country.name}`,
            value: country.code,
            key: country.code,
          }))}
          defaultValue={value?.language}
          //style={{ margin: 10 }}
          placeholder={"Select language"}
        >
          {world.map((x) => (
            <Option key={x.code} value={x.code}>
              {x.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col
        span={15}
        style={{ width: "100%", margin: 0, paddingLeft: 0, paddingRight: 0 }}
      >
        <Input
          type="text"
          //defaultValue={value?.language}
          placeholder="Language traduction ..."
          value={value.word || word}
          onChange={onWordChange}
        />
      </Col>
    </Row>
  );
};
export default LanguageComponent;
