import { Button, Card, Checkbox, Col, Form, Input, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { history } from "../../Helpers/history";
import { forgetPassword,createAccount } from "../../redux/auth/action"; //"redux/auth/action";
import { useHistory } from "react-router-dom";
import {
  DoubleLeftOutlined
} from "@ant-design/icons";
import Logo from "./../../assets/img/Logo.png";


const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const style = {};
const CreateAccountComponent = (props) => {
  let history = useHistory();
  const { message, email } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  // const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [phone, setPhone] = useState('');
  const [value, setValue] = useState('');



  const onFinish = (values) => {
    console.log("values ::::::::::::::::::", );
    // firstName,lastName, email,phone, password
    dispatch(createAccount(values.firstName, values.lastName, values.email, values.phone, values.password))
    // localStorage.setItem("email", values.user.email);

  };

  useEffect(() => {
    if (message){
      console.log("6666666666666",message);
      if (message == "API_REGISTRATION_SUCCEEDED") {
        alert('Account created successfully');
        history.push("/authentication/login");
      }
      if (message ==  "API_EMAIL_ALREADY_USED") {
        alert('Email already in use');
      }
    }
  }, [message])

  if (message == "Request failed with status code 400") {
    alert('email not valid');
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleChange = event => {
    const result = event.target.value.replace(/\D/g, '');

    setValue(result);
  };

  return (
    <div style={{ backgroundColor: "#FFDDFed", paddingTop:'160px' }}>
    <img src={Logo} className="logoImg"/>
    <Row gutter={[0, 0]}>
      <Col className="gutter-row" span={3}>
        {/* <div style={style}>col-6</div> */}
      </Col>
      <Col className="gutter-row" span={18}>
        <Row justify={"center"} align={"middle"} >
          <Card
            size="default"
            title="create account"
            extra={<div>  <Button type="link" htmlType="submit">
              <a href="/authentication/login"> <DoubleLeftOutlined/> Login </a>
              </Button> </div>}
            style={{ width: "70%" }}
          >
            <div style={style}>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 8,
                }}

                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item name='firstName' label="Firstname"
                  rules={[{ required: true, message: "Please input your email!" }]}>
                  <Input placeholder="firstname" />
                </Form.Item>

                <Form.Item
                  label="Lastname"
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your resetCode!' }]}
                >
                  <Input placeholder="lastname" />
                </Form.Item>
                <Form.Item name='email' label="Email"
                  rules={[{
                    required: true,
                    type: 'email',
                    message: "Please input your email!",
                  }]}>
                  <Input value={email}
                    placeholder="email"
                  // onChange={(val) => setemail(val.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="phone number"
                  name="phone"
                  rules={[{ required: true, message: 'Please input your phone number!' }]}
                >
                 <Input
                  type="number"
                  placeholder="Your fav number"
                  value={value}
                  onChange={handleChange}
                />
                </Form.Item>
               
                <Form.Item
                  label="password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password placeholder="password" />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit" >
                    submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Row>
      </Col>

    </Row>
    </div>

  );
};

export default CreateAccountComponent;