import { api } from "../Config/config";
api.setAccessToken(localStorage.getItem("accesstoken"));

class CategoryService {
  async getAllCategories(page, limit, filter) {
    try {
      console.log("FILTER MUISIC FROM SERVICE ", filter);
      const period = localStorage.getItem("period");
      let params = {};
      if (period && period !== ",") params.period = period;
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (filter) params = { ...params, ...filter };
      const { data } = await api.get(`/category`, { params });
      console.log(data);
      return data;
    } catch (e) {
      console.log("----sdfsdfsddf------dsfsfsd--", e);
    }
  }
  async addCategory(category) {
    try {
      console.log(category, "AUDIO");
      const form = new FormData();
      form.append("categoryName", category.categoryName);
      form.append("language", JSON.stringify(category.languages));
      category.catImage.map((image) =>
        form.append("file", image.originFileObj, image.filename)
      );

      const { data } = await api.post(`/category`, form);
      console.log(data);
      return data;
    } catch (e) {
      console.log("error while creating new category", e);
    }
  }
  async updateCategory(category) {
    try {
      const form = new FormData();
      form.append("categoryName", category.categoryName);
      form.append("language", JSON.stringify(category.languages));
      category?.catImage?.map((image) =>
        form.append("file", image.originFileObj, image.filename)
      );
      const { data } = await api.put(`/category/${category.id}`, form);
      console.log(data, "CAT SERVICE DATA");
      return data.data;
    } catch (e) {
      console.log("error while updating a category", e);
    }
  }
  async deleteCategory(category_id) {
    try {
        const data = await api(`/deleteCategory`, {
            method: "DELETE",
            data: { category_id }
        });
        console.log(" this is the request result :", data);
        return data;
    } catch (error) {
        console.log("error :", error);
    }
}
}
export default new CategoryService();
