import { Card, Col, Row, Typography, Skeleton } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useState, useCallback } from "react";
import PostsService from "../../../Services/PostsService";
import "./top_posts_card.css";
import PostDetails from "../../../Screens/posts/postDetails";
dayjs.extend(relativeTime);

const { Paragraph } = Typography;

const data = [1, 2, 3, 4];
const TopPostsCard = () => {
  const [topPosts, setTopPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPostDetails, setShowPostDetails] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const fetchTopPosts = () => {
    PostsService.getTopPosts()
      .then((data) => {
        setTopPosts(data);
        setIsLoading(false);
        console.log("TOP POSTS", data);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
        setTopPosts([]);
      });
  };

  const handleOpenPostDetails = useCallback(
    (post) => {
      setShowPostDetails(!showPostDetails);
      !showPostDetails === true
        ? setSelectedPost(post)
        : setSelectedPost(false);
    },
    [showPostDetails]
  );
  useEffect(() => {
    fetchTopPosts();

    return () => {};
  }, []);
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={12}
      xl={12}
      className={"top-posts"}
      style={{ padding: "20px", backgroundColor: "white" }}
    >
      <Card title="Top Posts" className={"card_container customheader "}>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 7 }} />
        ) : error ? (
          <div>error</div>
        ) : (
          <div>
            {topPosts.map((post, index) => (
              <Row
                key={index}
                className={"item-top-posts"}
                onClick={() => handleOpenPostDetails(post)}
              >
                <Col xs={12}>
                  <Row gutter={16} align={"middle"}>
                    <Col md={6}>
                      {post.medias?.images && post.medias?.images?.length ? (
                        <img
                          style={{
                            width: "60px",
                            height: "55px",
                            borderRadius: "12px",
                            marginRight: "8px",
                          }}
                          src={`${post?.medias?.images[0]?.url}`}
                        />
                      ) : (
                        post.medias?.videos &&
                        post.medias?.videos?.length && (
                          <img
                            style={{
                              width: "60px",
                              height: "55px",
                              borderRadius: "12px",
                              marginRight: "8px",
                            }}
                            src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${post?.medias?.videos[0]?.thumbnail}`}
                          />
                        )
                      )}
                    </Col>
                    <Col xs={18}>
                      <Row>
                        <Paragraph
                          className={"top-post-fields"}
                          ellipsis={{
                            rows: 1,
                          }}
                        >
                          <a>{post.description}</a>
                        </Paragraph>
                      </Row>
                      {/* <Row>Last Name</Row> */}
                    </Col>
                  </Row>
                </Col>
                <Col className={"top-post-fields"} span={5}>
                  {post.post_views_total} Views{" "}
                </Col>
                <Col className={"top-post-fields"} span={5}>
                  {dayjs(post.updatedAt).format("MMM D, YYYY")}
                </Col>
              </Row>
            ))}
          </div>
        )}
      </Card>
      {showPostDetails && (
        <PostDetails
          visible={showPostDetails}
          open={handleOpenPostDetails}
          item={selectedPost}
        />
      )}
    </Col>
  );
};

export default TopPostsCard;
