import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import "./assets/theme/css/antd.css";
import { verifyLogin } from "./redux/auth/action";
import AuthRoute from "./Routers/AuthRoute";
import MainRoute from "./Routers/MainRoute";
import UserRoute from "./Routers/UserRoute";
import PageLoder from "./Commun/PageLoder";
import Error from "./Screens/Error";
const defaultRoute = () => {
  return <Redirect to="/app" />;
};

function App(props) {
  const { isLoading } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const token = localStorage.getItem("accesstoken");
  useEffect(() => {
    //setup search filter periode oneweek/twoweek/lastmonth
    localStorage.setItem("period", ",");
    //verify token data
    dispatch(verifyLogin(token));
  }, [token]);

  return isLoading ? (
    <div>
      <PageLoder />
    </div>
  ) : (
    <Router>
      <Switch>
        <Route path="/" exact component={defaultRoute} />
        <Route path="/authentication" component={UserRoute} />
        <AuthRoute path="/app" component={MainRoute} />
        <Route path="/error" component={Error} />
        <Redirect to="/error" />
      </Switch>
    </Router>
  );
}

export default App;
