import React from "react";
import { Spin } from "antd";
const PageLoder = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin tip="Loading..." size={"large"} />
    </div>
  );
};

export default PageLoder;
