import React, { useState, useEffect, useCallback } from "react";
import { Select, Button, Form, Spin, message, Avatar } from "antd";
import UsersService from "../Services/UsersService";

const { Option } = Select;
const avatarStyle = {
  color: "#999",
  backgroundColor: "#fff",
};
const loadingStyle = {
  display: "flex",
  justifyContent: "center",
};
const nameStyle = { marginLeft: 8 };
const optionStyle = {
  display: "flex",
  alignItems: "center",
};
export default function SearchSelect(
  { data, placeholder, style, size, onChange },
  props
) {
  const [value, setValue] = useState(props.value || []);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState();
  const [limit, setLimit] = useState(10);
  const fetchUsers = () => {
    setLoading(true);
    UsersService.getAllUsers(page, limit, filter)
      .then((data) => {
        setUsers((prev) => [...prev, ...data.data]);
        setTotal(data?.attrs?.total);
        setLoading(false);
      })
      .catch((e) => {
        setUsers([]);
        setLoading(false);
      });
  };
  const handleSearch = useCallback((text) => {
    console.log("Im text :: ", text);
    setUsers([]);
    if (text)
      setFilter({
        ...filter,
        searchTxt: text,
      });
    else {
      let x = filter;
      delete x?.searchTxt;
      setUsers([]);
      setTotal(0);
      fetchUsers(page, limit, x);
    }
  });
  const handlePageChange = useCallback(
    (page) => {
      alert("page" + page);
      setPage(page);
    },
    [page]
  );
  const handleLimitChange = useCallback(
    (limit) => {
      setLimit(limit);
    },
    [limit]
  );
  const handleFilterChange = useCallback(
    (v) => {
      console.log("USER FILTER // ", v);
      switch (v) {
        case "Verified":
          delete filter?.isTalent;
          setFilter({ ...filter, isVerified: true, active: "Y" });
          return;
        case "Talent":
          delete filter?.isVerified;
          setFilter({ ...filter, isTalent: true, active: "Y" });
          return;
        case "Blocked":
          delete filter?.active;
          setFilter({ ...filter, active: "B" });
          return;
        default:
          delete filter?.isTalent;
          delete filter?.isVerified;
          setFilter({ ...filter, active: "Y" });
          return;
      }
    },
    [filter]
  );
  useEffect(() => {
    //console.log("filter", filter, "page", page);
    if (loading || !filter?.searchTxt) return;
    fetchUsers(page, limit, filter); //fetch_users(page, limit, filter);
    return () => {};
  }, [page, limit, filter?.searchTxt]);

  const handleChange = (value) => {
    //if (typeof onChange === "function") props.onChange(value);
    setValue(value);
    onChange(value);
  };
  const onScroll = (event) => {
    var target = event.target;
    if (
      Math.ceil(target.scrollTop + target.offsetHeight) >=
      target.scrollHeight - 5
    ) {
      //if (users.length < total) setPage(page + 1);
    }
  };
  const renderOptions = () => {
    return users.map((item) => (
      <Option key={item.user_id}>
        <div style={optionStyle}>
          <Avatar
            src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${item.profile_image}`}
            style={avatarStyle}
            icon="shop"
            size="middle"
          />
          <span style={nameStyle}>{item.fullName}</span>
        </div>
      </Option>
    ));
  };
  const getNotFoundContent = () => {
    if (!loading) return null;
    return (
      <div style={loadingStyle}>
        <Spin size="small" />
      </div>
    );
  };
  return (
    <Select
      showSearch
      mode="multiple"
      filterOption={false}
      defaultActiveFirstOption={false}
      value={value}
      style={style}
      size={size}
      placeholder={placeholder}
      notFoundContent={getNotFoundContent()}
      onSearch={handleSearch}
      onChange={handleChange}
      //   onBlur={() => {
      //     handleSearch("");
      //   }}
      onPopupScroll={onScroll}
    >
      {renderOptions()}
    </Select>
  );
}
