import { LOGIN_FAIL, LOGIN_SUCCESS, FORGET_PASSWORD_EMAIL_SENT, FORGET_PASSWORD_EMAIL_FAILED, FAIL_EMAIL, DIGIT_VERIFIED, SUCCESS_PASSWORD_RESET, CREATE_ACCOUNT, WRONG_CREDENTIALS, API_FORBIDDEN } from "../actions";
const accesstoken = localStorage.getItem("accesstoken");
const initialState = accesstoken
  ? {
    isLoading: true,
    user: null,
    message: null,
    email: null
  }
  : {
    isLoading: false,
    user: null,
    message: null,
    email: null
  };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload,
        message: null
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        user: null,
      };
    case FORGET_PASSWORD_EMAIL_SENT:
      return {
        ...state,
        isLoading: false,
        user: null,
        email: payload.email,
        message: payload.message
      };
    case FORGET_PASSWORD_EMAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        user: null,
        email: null,
        message: payload.message
      };
    case FAIL_EMAIL:
      return {
        ...state,
        isLoading: false,
        user: null,
        email: null,
        message: null
      };
    case DIGIT_VERIFIED:
      return {
        ...state,
        isLoading: false,
        user: null,
        email: null,
        message: payload.message
      };
    case SUCCESS_PASSWORD_RESET:
      return {
        ...state,
        isLoading: false,
        user: null,
        email: null,
        message: payload.message
      };
    case CREATE_ACCOUNT:
      return {
        ...state,
        isLoading: false,
        user: null,
        email: null,
        message: payload.message
      }
    case WRONG_CREDENTIALS:
      console.log("payload 222222222",payload);
      return {
        ...state,
        isLoading: false,
        user: null,
        email: null,
        message: payload
      }
      case API_FORBIDDEN:
        return {
          ...state,
          isLoading: false,
          user: null,
          email: null,
          message: payload
        }

    default:
      return state;
  }
}
