import {
  Col,
  Modal,
  Row,
  Image,
  Space,
  Upload,
  message,
  Form,
  Input,
  Switch,
  Button,
} from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import LanguageComponent from "../../../Components/Categories/LanguageComponent";
import React, { useEffect, useState } from "react";
import CategoryService from "../../../Services/CategoryService";
import ReportsService from "../../../Services/ReportsService";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 6 },
    sm: { span: 24, offset: 6 },
  },
};
const UpdateMotif = ({ visible, open, item }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    values.id = item._id;
    let lang = {};
    values.languages.map((x) => {
      let keys = Object.keys(x);
      lang[x.language] = x.word;
      return lang;
    });
    lang["en"] = values.motifTitle;
    values.tag_type = lang;
    delete values.languages;
    delete values.motifTitle;
    console.log("values", values);
    open(false);
    ReportsService.updateReportMotif(values).then(() => open(true));
  };
  const onReset = () => {
    form.resetFields();
    open(true);
  };
  useEffect(() => {
    // form.setFields({ audio: "test" });
  }, []);
  return (
    item && (
      <div>
        <Modal
          centered
          title={"Update report motif"}
          visible={visible}
          onOk={() => open()}
          onCancel={() => {
            open();
            onReset();
          }}
          footer={null}
          destroyOnClose
          width={"50%"}
        >
          <Row gutter={16}>
            <Form
              form={form}
              {...layout}
              style={{ width: "100%", padding: 30 }}
              labelAlign="left"
              name="nest-messages"
              onFinish={onFinish}
              initialValues={{
                ...item,
                motifTitle: item?.tag_type["en"],
                languages: Object.keys(item.tag_type).map((x) => {
                  let option = {};
                  option.language = x;
                  option.word = item.tag_type[x];
                  console.log("IM THE OPTION LANG :", option);
                  return option;
                }),
              }}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={"motifTitle"}
                label="Default title (EN)"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Default category title (EN)" />
              </Form.Item>
              <Form.List
                name="languages"
                rules={[
                  {
                    required: true,
                    validator: async (_, names) => {
                      if (!names || names.length < 2) {
                        return Promise.reject(
                          new Error("At least 2 languages")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0
                          ? formItemLayout
                          : formItemLayoutWithOutLabel)}
                        label={index === 0 ? "Traductions" : ""}
                        required={false}
                        key={field.key}
                      >
                        <Row>
                          <Col span={fields.length > 1 ? 22 : 24}>
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please input language or delete this field.",
                                },
                              ]}
                              noStyle
                            >
                              <LanguageComponent value={{ word: "sdfsdf" }} />
                            </Form.Item>
                          </Col>
                          <Col>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                style={{ marginLeft: 10 }}
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}

                    <Form.Item {...tailLayout}>
                      <Row justify="center">
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                        >
                          Add Language
                        </Button>

                        <Form.ErrorList errors={errors} />
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item
                name={"active"}
                label="Status"
                initialValue={item?.active ? item?.active : false}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Switch
                  defaultChecked={item?.active}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Row gutter={16} justify="center">
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      htmlType="button"
                      onClick={onReset}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Row>
        </Modal>
      </div>
    )
  );
};

export default UpdateMotif;
