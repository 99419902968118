import React, { useState, useEffect } from "react";
import { Card, Col } from "antd";
import "./chart_card.css";
import { Line } from "react-chartjs-2";
import * as dayjs from "dayjs";
import PostsService from "../../../Services/PostsService";

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
let currentMonth = dayjs().month();
const data = {
  labels: months, //.slice(currentMonth - 6), //.concat(months.slice(0, currentMonth)),
  datasets: [
    {
      label: "",
      data: [],
      fill: true,
      backgroundColor: "#D3D3D3",
      borderColor: "rgba(255, 99, 132, 0.2)",
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  plugins: {
    responsive: true,
    legend: {
      display: false,
    },
    // tooltip: {
    //   enabled: false,
    //   position: "nearest",
    //   external: externalTooltipHandler,
    // },
  },
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        stepSize: 20,
        // Include a dollar sign in the ticks
        callback: function (value, index, values) {
          return value >= 1000 ? value / 1000 + "K" : value;
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};
const ChartCard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const fetch_views_per_month = () => {
    PostsService.getPostsMonthsViews()
      .then((response) => {
        data.datasets[0].data = response;
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        data.datasets[0].data = [];
      });
  };
  useEffect(() => {
    fetch_views_per_month();
    return () => {};
  }, []);
  return isLoading ? (
    <div>isloading</div>
  ) : (
    <Col
      className={"chart-cart"}
      style={{ backgroundColor: "white" }}
      xs={24}
      sm={24}
      md={24}
      lg={14}
      xl={14}
    >
      <div className={"card_container"}>
        <div className={"title"}>Top View</div>
        <Line data={data} options={options} height={"150px"} />
      </div>
    </Col>
  );
};

export default ChartCard;
