import React, { useState } from "react";
import "./button-group.css";
import { Button, Col, Row } from "antd";
import {
  AntDesignOutlined,
  CheckCircleFilled,
  StarFilled,
  DownloadOutlined,
} from "@ant-design/icons";

const renderIcons = (filter) => {
  switch (filter) {
    case "Verified":
      return <CheckCircleFilled size={24} />;
    case "Talent":
      return <StarFilled size={24} />;
    default:
      return null;
  }
};

const FilterButonGroup = ({ filters, onFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState(0);
  const handleFilterChange = (index, filter) => {
    setSelectedFilter(index);
    console.log("USER ACTIVITIES FILTERS 0000: ", filter);
    if (filter === "Feechred") onFilter({ type: "post", post_parent_id: -1 });
    /*old concept before schema update     if (filter === "Feechred") onFilter({ type: "post", post_parent_id: -1 });
     */
    if (filter === "Feechred") onFilter({ type: "feechred" });
    else if (filter === "Feechring") onFilter({ type: "feechring" });
    else if (filter === "Re-Feechr")
      onFilter({ type: "refeechr", post_parent_id: 1 });
    else if (filter === "Verified") onFilter("Verified");
    else if (filter === "Talent") onFilter("Talent");
    else if (filter === "Blocked users") onFilter("Blocked");
    else onFilter({});
  };
  return (
    <Row className={"btn-group"}>
      {filters?.map((x, index) => (
        <Button
          key={index}
          className={selectedFilter === index && "selected-btn-options"}
          icon={renderIcons(x)}
          onClick={() => handleFilterChange(index, x)}
        >
          {x}
        </Button>
      ))}
    </Row>
  );
};

export default FilterButonGroup;
