import { AntDesignOutlined, CheckCircleFilled } from "@ant-design/icons";
import React, { useState } from "react";
import { Avatar, Button, Col, Row, Typography, Modal } from "antd";
import "./user-profile.css";
import UsersService from "../../Services/UsersService";


const { Paragraph } = Typography;
const UserProfileHeader = ({ user }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    let status;
    if (user.active == "Y" || user.active == "A") {
      status = 'B'
    } else {
      status = 'Y'
    }
    UsersService.updateUserStatus(user.user_id, status)
      .then(({ data }) => {
        window.location.reload(true);
      })
      .catch((e) => {
        console.log("error :", e);
      });

  };

  return (
    <div>
      <Modal title="update user status" visible={isModalVisible} onOk={() => handleOk(user)} onCancel={handleCancel}>
        would you like to {user.active == "Y" ? "block" : "unblock" } user ?
      </Modal>
      <Row className={"profile-header"} align={"middle"}>
        <Col xs={24} sm={24} md={14} lg={14} xl={14} xl={16}>
          <Row align={"middle"}>
            <Col xs={24} sm={4} md={4} lg={4} xl={5} xxl={4}>
              <Avatar
                src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${user?.profile_image}`}
                size={{ xs: 80, sm: 80, md: 40, lg: 70, xl: 110, xxl: 120 }}
                icon={<AntDesignOutlined />}
              />
            </Col>
            <Col xs={24} sm={14} md={14} lg={14} xl={16} xxl={16}>
              <Row align={"middle"} gutter={16}>
                <Col className={"title"}>{user?.fullName}</Col>
                <Col className={""}>
                  <Row>
                    <Col>
                      <div
                        className={
                          user?.isVerified
                            ? "active-isverified-button"
                            : "disabled-button"
                        }
                      >
                        <CheckCircleFilled
                          style={{
                            color: user?.isVerified ? "white" : "#cfcfcf50",
                            marginRight: "5px",
                          }}
                        />{" "}
                        isVerified
                      </div>
                    </Col>
                    <Col>
                      <div
                        className={
                          user?.isTalent
                            ? "active-istalent-button"
                            : "disabled-button"
                        }
                      >
                        <CheckCircleFilled
                          style={{
                            color: user?.isTalent ? "white" : "#cfcfcf50",
                            marginRight: "5px",
                          }}
                        />{" "}
                        isTalent
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>@Morgan</Row>
              <Row>
                <Paragraph
                  style={{ width: "-webkit-fill-available" }}
                  ellipsis={{
                    rows: 3,
                    expandable: true,
                    symbol: "read more",
                    onEllipsis: (ellipsis) => {
                      console.log("Ellipsis changed:", ellipsis);
                    },
                  }}
                >
                  {user.description ||
                    "Morgan Anny has been featured in a number  of modeling assignments, including a feature in the Sports Illustrated Swimsuit Issue.}||Morgan Anny has been featured in a number of modeling assignments, including a feature in the Sports"}
                </Paragraph>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={6} lg={8} xl={8}>
          <Row justify={"end"}>
            <Button onClick={showModal} style={{ marginBottom: "20px" }} type="secondary">
              {user.active === "Y" || user.active === "A" ? "Block user" : "Enable user"}
            </Button>
          </Row>
          <Row justify={"end"}>
            <Col span={24}>
              <Row justify={"space-between"}>
                <Col>
                  <Row className={"info-title"}>{user.posts_count}</Row>
                  <Row>Posts</Row>
                </Col>
                <Col>
                  <Row className={"info-title"}>{user.views_count}</Row>
                  <Row>Views</Row>
                </Col>
                <Col>
                  <Row className={"info-title"}>{user.followers_count}</Row>
                  <Row>Followers</Row>
                </Col>
                <Col>
                  <Row className={"info-title"}>{user.following_count}</Row>
                  <Row>Following</Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UserProfileHeader;
