import { FETCH_USERS_SUCCESS, FETCH_USERS_FAIL } from "../actions";
import UsersService from "../../Services/UsersService";

export const fetch_users = (page, limit, filter) => (dispatch) => {
  return UsersService.getAllUsers(page, limit, filter).then(
    (data) => {
      return dispatch({
        type: FETCH_USERS_SUCCESS,
        payload: data,
      });
    },
    (err) => {
      return dispatch({
        type: FETCH_USERS_FAIL,
        payload: err,
      });
    }
  );
};
