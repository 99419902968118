import React, { useState, useCallback } from "react";
import { Pagination, Row, Table, Tag, Radio, Button, Modal, Col, Divider, Image, Space } from "antd";
import ClubDetails from "../../Screens/clubs/clubDetails";
import ConfirmVerification from "../../Screens/verification/ConfirmVerification";
import UserService from "../../Services/UsersService";
import Moment from "moment";





const size = 40;
export default function AdminTable({
    data,
    total,
    currentLimit,
    currentPage,
    onLimit,
    onPage,
    updateVisible,
    handleOpenUpdateModal,
}) {
    const [selectionType, setSelectionType] = useState("checkbox");
    const [selectedMotif, setSelectedMotif] = useState(null);
    const [showPostDetails, setShowPostDetails] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [error, setError] = useState(false);
    const [selectDisableButton, setselectDisableButton] = useState(false);
    const [selectEnableAdmin, setSelectEnableAdmin] = useState(false);

    const [value, setValue] = useState("all");
    const [select, setSelect] = useState("")

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
            selectedRows?.length > 0 && setSelectedMotif(selectedRows);
            if (selectedRows.map(sel => sel.isActive).includes(false)) {
                setSelectEnableAdmin(true)
            } else {
                setSelectEnableAdmin(false)
            }
            if (selectedRows.length > 0) {
                setselectDisableButton(true)
            } else {
                setselectDisableButton(false)
            }
        },
        getCheckboxProps: (record) => ({
            //disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const verif_columns = [
        {
            title: "",
            dataIndex: "medias",
            render: (media) =>
                // media?.images.length > 0 ? (
                //   <PictureOutlined style={{ fontSize: "20px" }} />
                // ) : media?.videos.length > 0 ? (
                //   <YoutubeFilled style={{ fontSize: "20px" }} />
                // ) :
                null,
        },
        {
            title: "Firstname",
            dataIndex: "firstName",
        },
        {
            title: "Lastname",
            dataIndex: "lastName",
        },
        {
            title: "Email",
            dataIndex: "email",
            responsive: ["md"],
        },
        {
            title: "Phone",
            dataIndex: "phone",
            responsive: ["md"],
        },
        {
            title: "status",
            dataIndex: "isActive",
            responsive: ["md"],
            render: (text, post) => (
                <div>
                    {post.isActive == true ? "enabled" : "disabled"}
                </div>
            ),
        },
        {
            title: "Creation date",
            dataIndex: "createdAt",
            render: (text, post) => (
                <div>
                    {/* Moment( "2013-2-1 14:00:00" ).format( "dddd h:mma D MMM YYYY" ) ); */}
                    {Moment(`${post.createdAt}`).format("dddd h:mma D MMM YYYY")}
                </div>
            ),
        },
    ];

    const handleOpenPostDetails = useCallback(
        (audio) => {
            setShowPostDetails(!showPostDetails);
            !showPostDetails === true
                ? setSelectedMotif(audio)
                : setSelectedMotif(false);
        },
        [showPostDetails]
    );


    const showModal = (element) => {
        setSelect(element)
        setIsModalVisible(true);
    };

    const handleDelete = () => {
        let adminArray = [];
        for (let i = 0; i < selectedMotif.length; i++) {
            adminArray.push(selectedMotif[i]._id);
        }
        let token = localStorage.getItem("accesstoken");
        UserService.deleteAdmins(adminArray, token)
            .then(({ data }) => {
                setIsModalVisible(false);
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
                setError(true);
            });
    };

    const handleEnable = () => {
        let adminArray = [];
        for (let i = 0; i < selectedMotif.length; i++) {
            adminArray.push(selectedMotif[i]._id);
        }
        let token = localStorage.getItem("accesstoken");
        UserService.enableAdmins(adminArray, token)
            .then(({ data }) => {
                setIsModalVisible(false);
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
                setError(true);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <div>
            {/* <Radio.Group onChange={onChange} value={value} > */}

            {/* onClick={() => {
                        handleOpenPostDetails();
                        post.selectedImage = "image"
                        setSelectedPost(post);
                    }} */}
            <Button type="primary" onClick={() => showModal("delete")} disabled={!selectDisableButton} >
                Delete admin
            </Button>
            <Button style={{ position: "relative", left: "10px" }} type="primary" onClick={() => showModal("enable")} disabled={!selectEnableAdmin} >
                enable admin
            </Button>
            {select == "delete" ? <Modal title="Delete event" visible={isModalVisible} onOk={handleDelete} onCancel={handleCancel}>
            would you {select == "delete" ? "delete" : "enable"} the following selected admin(s) ?
        </Modal>
            : <Modal title="Enable admin" visible={isModalVisible} onOk={handleEnable} onCancel = { handleCancel } >
    would you { select == "delete" ? "delete" : "enable" } the following selected admin(s) ?
        </Modal > }
            
            <Divider />
            <Table
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                columns={verif_columns}
                dataSource={data?.map((x, i) => ({ ...x, key: i }))}
                pagination={false}
            />
            <Row justify={"end"} style={{ marginTop: "20px" }}>
                <Pagination
                    className={"pagination"}
                    defaultCurrent={1}
                    current={Number(currentPage)}
                    total={total}
                    onChange={(page, pageSize) => {
                        if (page !== currentPage) onPage(page);
                        if (pageSize !== currentLimit) onLimit(pageSize);
                    }}
                />
            </Row>

        </div >
    );
}
