import { Col, Modal, Row, Image, Space } from "antd";
import React, { useEffect, useState } from "react";
import CommentsCard from "../../Components/Posts/CommentsCard";
import PostCard from "../../Components/Posts/PostCard";
import PostsService from "../../Services/PostsService";
import ReactPlayer from "react-player";
const MusicDetails = ({ visible, open, item }) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  console.log("IMTHE ITEM :: ", item);
  // const fetch_post_by_id = (id) => {
  //   PostsService.getPostById(id)
  //     .then((data) => {
  //       setPost(data);
  //       setIsLoading(false);
  //     })
  //     .catch((e) => setError(e));
  // };
  // useEffect(() => {
  //   item && fetch_post_by_id(item._id);
  //   return () => {
  //     setIsLoading(true);
  //   };
  // }, [item]);
  return (
    <div>
      <Modal
        centered
        title={"Audio Details"}
        visible={visible}
        onOk={() => open(null)}
        onCancel={() => open(null)}
        footer={null}
        width={"60%"}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Image style={{ width: 400 }} src={item.albumImage} />
            <ReactPlayer
              style={{
                position: "absolute",

                bottom: 0,
              }}
              width={400}
              height={50}
              url={item.s3Url}
              // light={`https://digit-u-media-resources.s3.amazonaws.com/1640774993638-jpeg`}
              controls={true}
            />
          </Col>
          <Col span={12}>
            <Space
              style={{
                padding: 20,
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
              }}
              direction="vertical"
            >
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Name
                </Col>
                <Col xs={12}>{item.name}</Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Author
                </Col>
                <Col xs={12}>{item.author}</Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Genre
                </Col>
                <Col xs={12}>{item.author}</Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  used count{" "}
                </Col>
                <Col xs={12}>{item.usedCount}</Col>
              </Row>
              <Row style={{}}>
                <Col
                  xs={12}
                  className={"level-two-title"}
                  style={{ textAlign: "left" }}
                >
                  <div style={{ alignSelf: "left" }}> Date </div>
                </Col>
                <Col xs={12}>{item.createdAt}</Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default MusicDetails;
