import { FETCH_USERS_FAIL, FETCH_USERS_SUCCESS, SET_PERIOD } from "../actions";
const initialState = {
  isLoading: true,
  usersList: [],
  total_users: 0,
  period: {},
  findOptionsUsers: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USERS_SUCCESS:
      console.log(
        "Fetch users payload from Reducer ** *",
        payload?.attrs?.where
      );
      return {
        ...state,
        isLoading: false,
        usersList: payload?.data,
        total_users: payload?.attrs?.total,
        findOptionsUsers: payload?.attrs?.where,
      };
    case FETCH_USERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
        usersList: [],
      };
    case SET_PERIOD:
      return {
        ...state,
        period: payload,
      };
    default:
      return state;
  }
}
