import { Col, Modal, Row, Image, Space, Tag, Button, Divider, Form } from "antd";
import React, { useEffect, useState } from "react";
import randomColor from "randomcolor";
import VerificationRequestServices from "../../Services/VerificationAccount";

const EventDetails = ({ visible, open, item }) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMotif, setSelectedMotif] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);


  return (
    <div>
      <Modal
        centered
        title={"Category Details"}
        visible={visible}
        onOk={() => open(null)}
        onCancel={() => open(null)}
        footer={null}
        width={"60%"}
      >
        <Row gutter={16}>
          <Col span={12}>
            {item.selectedImage == "image" ? <Image src={process.env.REACT_APP_BASE_S3_VIDEO_URL + item.eventProfileImage} /> : <Image src={process.env.REACT_APP_BASE_S3_VIDEO_URL + item.eventCoverImage} />}
          </Col>
          <Col span={12}>
            <Space
              style={{
                padding: 20,
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
              }}
              direction="vertical"
            >
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Event name
                </Col>
                <Col xs={12}>{item?.eventName}</Col>
              </Row>

              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Event Type
                </Col>
                <Col xs={12}>{item["eventType"] == "streetEvent" ? "street event" : "event"}</Col>
              </Row>

              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  total comments
                </Col>
                <Col xs={12}>
                  {item?.comment_total_count}
                </Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  total members
                </Col>
                <Col xs={12}>
                  {item?.members_total_count}
                </Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  total views
                </Col>
                <Col xs={12}>
                  {item?.event_views_count}
                </Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  tickets
                </Col>
                <Col xs={12}>
                  {item.tickets.available == true ? "Available" : "Not available"}
                </Col>
              </Row>

            </Space>
          </Col>
          <Divider />
        </Row>
        {/* <Form.Item >
          <Row gutter={16} justify="center">
            <Col>
              <Button
                style={{ width: 200 }}
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Col>
            <Col>
              <Button
                style={{ width: 200 }}
                htmlType="button"
                onClick={() => open(null)}
              >
                cancel
              </Button>
            </Col>
          </Row>
        </Form.Item> */}
      </Modal>
    </div>
  );
};

export default EventDetails;
