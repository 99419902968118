import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import CardStat from "../Components/OverView/CardStat/cardStat";
import TopUsersCard from "../Components/OverView/TopUsersCard/topUsersCard";
import TopPostsCard from "../Components/OverView/TopPostsCard/topPostsCard";
import ChartCard from "../Components/OverView/ChartCard/chartCard";
import HashTagsCard from "../Components/OverView/HashTagsCard/hashTagsCard";
import PostsService from "../Services/PostsService";

const Overview = () => {
  const [allViews, setAllViews] = useState({});
  const fetch_overview = () => {
    PostsService.getOverView().then((data) => setAllViews(data));
  };
  useEffect(() => {
    fetch_overview();
    return () => { };
  }, []);
  return (
    <div style={{}}>
      <Row>
        <CardStat
          title={"All users"}
          counter={allViews.total_users}
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={6}
        />
        <CardStat
          title={"All posts"}
          counter={allViews.total_posts}
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={6}
        />
        <CardStat
          title={"All views"}
          counter={allViews.total_views}
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={6}
        />
        <CardStat
          title={"All reactions"}
          counter={allViews.total_reactions}
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={6}
        />
      </Row>
      <Row>
      <ChartCard />
      <HashTagsCard />
      <TopUsersCard />
      <TopPostsCard />
      </Row>
    </div>
  );
};

export default Overview;
