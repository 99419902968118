import React, { useState, useCallback } from "react";
import { Pagination, Row, Table, Tag, Radio, Button, Modal, Col, Divider, Image, Space } from "antd";
import PostDetails from "../../Screens/posts/postDetails";
import VerificationRequestDetails from "../../Screens/verification/VerificationRequestDetails";
import ConfirmVerification from "../../Screens/verification/ConfirmVerification";
import VerificationRequestServices from "../../Services/VerificationAccount";




const size = 40;
export default function VerificationTable({
    data,
    total,
    currentLimit,
    currentPage,
    onLimit,
    onPage,
    updateVisible,
    handleOpenUpdateModal,
}) {
    const [selectionType, setSelectionType] = useState("checkbox");
    const [selectedMotif, setSelectedMotif] = useState(null);
    const [showPostDetails, setShowPostDetails] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [error, setError] = useState(false);
    const [selectDisableButton, setselectDisableButton] = useState(false);





    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
            selectedRows?.length > 0 && setSelectedMotif(selectedRows);
            if (selectedRows.length > 0) {
                setselectDisableButton(true)
            } else {
                setselectDisableButton(false)
            }
        },
        getCheckboxProps: (record) => ({
            //disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    //   active: "Y"
    // categories: (2) [{…}, {…}]
    // category: null
    // createdAt: "2022-06-21"
    // fullName: "talent_jdid_felhouma"
    // image: "afe848d7-2952-4226-b9a4-34d4c19a94c4.jpg"
    // known_as: "jadjoud"
    // updatedAt: "2022-06-22"
    // user_id: 663
    // username: "tahtouh_talent_jdid_felhouma"
    // verifProfil_id
    const verif_columns = [
        {
            title: "",
            dataIndex: "medias",
            render: (media) =>
                // media?.images.length > 0 ? (
                //   <PictureOutlined style={{ fontSize: "20px" }} />
                // ) : media?.videos.length > 0 ? (
                //   <YoutubeFilled style={{ fontSize: "20px" }} />
                // ) :
                null,
        },
        {
            title: "image",
            dataIndex: "createdAt",
            render: (text, post) => (
                <a
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    onClick={() => {
                        handleOpenPostDetails();
                        setSelectedPost(post);
                    }}
                    href="#"
                >
                    <div>
                        <img
                            style={{
                                width: "60px",
                                height: "55px",
                                borderRadius: "12px",
                                marginRight: "8px",
                            }}
                            src={`${process.env.REACT_APP_BASE_S3_VIDEO_URL}${post.image}`}
                        />
                    </div>
                    <div style={{ maxWidth: "450px" }}>
                        {/* <p>{media.name}</p> */}
                    </div>
                </a>
            ),
        },
        {
            title: "known_as",
            dataIndex: "known_as",
            responsive: ["md"],
        },
        {
            title: "fullName",
            dataIndex: "fullName",
        },
        {
            title: "user_id",
            dataIndex: "user_id",
        },
        {
            title: "Date",
            dataIndex: "createdAt",
        },

    ];

    const handleOpenPostDetails = useCallback(
        (audio) => {
            setShowPostDetails(!showPostDetails);
            !showPostDetails === true
                ? setSelectedMotif(audio)
                : setSelectedMotif(false);
        },
        [showPostDetails]
    );


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        let totalData = 0;
        for (let i = 0; i < selectedMotif.length; i++) {
            VerificationRequestServices.agreeVerificationAccount(selectedMotif[i].user_id)
                .then(({ data }) => {
                    console.log("_______________ agreeVerificationAccount :: ", data);
                    totalData += 1
                    if (totalData == Number(selectedMotif.length)) {
                        setIsModalVisible(false);
                        window.location.reload();
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setError(true);
                });
        }

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <div className="tableContainer" >
            <Button type="primary" onClick={showModal} disabled={!selectDisableButton} >
                Confirm request
            </Button>
            <Modal title="Confirm verification" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            would you confirm the following verification requests ?
            </Modal>
            <Divider />
            <Table
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                columns={verif_columns}
                dataSource={data?.map((x, i) => ({ ...x, key: i }))}
                pagination={false}
            />
            <Row justify={"end"} style={{ marginTop: "20px" }}>
                <Pagination
                    className={"pagination"}
                    defaultCurrent={1}
                    current={Number(currentPage)}
                    total={total}
                    onChange={(page, pageSize) => {
                        if (page !== currentPage) onPage(page);
                        if (pageSize !== currentLimit) onLimit(pageSize);
                    }}
                />
            </Row>

            {showPostDetails && (
                <VerificationRequestDetails
                    visible={showPostDetails}
                    open={handleOpenPostDetails}
                    item={selectedPost}
                />
            )}
            {/* <ConfirmVerification
                visible={updateVisible}
                open={handleOpenUpdateModal}
                item={selectedPost}
            /> */}
        </div>
    );
}
