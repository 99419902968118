import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { user_filters } from "../../Commun/constant";
import FilterButonGroup from "../../Components/FilterButton/FilterButonGroup";
import UsersTable from "../../Components/Users/usersTable";
import { fetch_users } from "../../redux/users/action";
import { CSVDownloader } from "react-papaparse";
import UsersService from "../../Services/UsersService";

const Users = () => {
  const dispatch = useDispatch();

  const { usersList, isLoading, error, total_users } = useSelector(
    (state) => state.usersReducer
  );

  const [exportedData, setExportedData] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState();
  const [limit, setLimit] = useState(10);

  const loadDataToExport = () => {
    UsersService.getAllUsers(0, 0, filter)
      .then(({ data }) => {
        console.log("DATA TO EXPORT SEE ME :", data);
        setExportedData(data);
      })
      .catch((e) => {
        setExportedData([]);
      });
  };
  const handleSearch = useCallback((text) => {
    if (text.target.value)
      setFilter({
        ...filter,
        searchTxt: text.target.value,
      });
    else {
      let x = filter;
      delete x.searchTxt;
      dispatch(fetch_users(page, limit, x));
    }
  });
  const handlePageChange = useCallback(
    (page) => {
      alert("page" + page);
      setPage(page);
    },
    [page]
  );
  const handleLimitChange = useCallback(
    (limit) => {
      setLimit(limit);
    },
    [limit]
  );
  const handleFilterChange = useCallback(
    (v) => {
      console.log("USER FILTER // ", v);
      switch (v) {
        case "Verified":
          delete filter?.isTalent;
          setFilter({ ...filter, isVerified: true, active: "Y" });
          return;
        case "Talent":
          delete filter?.isVerified;
          setFilter({ ...filter, isTalent: true, active: "Y" });
          return;
        case "Blocked":
          delete filter?.active;
          setFilter({ active: "B" });
          return;
        default:
          delete filter?.isTalent;
          delete filter?.isVerified;
          setFilter({ ...filter, active: "Y" });
          return;
      }
    },
    [filter]
  );
  useEffect(() => {
    //console.log("filter", filter, "page", page);
    dispatch(fetch_users(page, limit, filter)); //fetch_users(page, limit, filter);
    return () => {};
  }, [page, limit, filter]);
  useEffect(() => {
    //console.log("filter", filter, "page", page);
    loadDataToExport();
    return () => {};
  }, [filter]);
  return (
    <div>
      <Row style={{ marginBottom: "20px" }} justify={"space-between"}>
        <Col>
          <Row gutter={16}>
            <FilterButonGroup
              filters={user_filters}
              onFilter={handleFilterChange}
            />
          </Row>
        </Col>
        <Col>
          <Row className={"btn-group"}>
            <CSVDownloader
              data={exportedData}
              type="button"
              filename={"users"}
              bom={true}
              style={{ borderRadius: 20, width: 150, height: 30 }}
              className={["selected-btn"]}
            >
              Download
            </CSVDownloader>
          </Row>
        </Col>
        <Col>
          <Input
            style={{
              borderRadius: "20px",
              width: "150px",
              marginRight: "10px",
            }}
            size="default"
            placeholder="Search"
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>
      {error ? (
        <div>error</div>
      ) : isLoading ? (
        <Skeleton />
      ) : (
        <UsersTable
          data={usersList}
          total={total_users}
          page={page}
          limit={limit}
          onPage={handlePageChange}
          onLimit={handleLimitChange}
        />
      )}
    </div>
  );
};

export default Users;
