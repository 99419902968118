import { FETCH_POSTS_SUCCESS, FETCH_POSTS_FAIL } from "../actions";
import PostsService from "../../Services/PostsService";

export const fetch_posts =
  //(page, limit, searchTxt, userId, filter)
  (userId, filter) => (dispatch) => {
    return PostsService.getPostes(userId, filter) //(page, limit, searchTxt, userId, filter)
      .then(
        (data) => {
          return dispatch({
            type: FETCH_POSTS_SUCCESS,
            payload: data,
          });
        },
        (err) => {
          return dispatch({
            type: FETCH_POSTS_FAIL,
            payload: err,
          });
        }
      );
  };
