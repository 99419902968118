import AuthService from "../../Services/AuthService";
import { LOGIN_FAIL, LOGIN_SUCCESS, FORGET_PASSWORD_EMAIL_SENT, FORGET_PASSWORD_EMAIL_FAILED, DIGIT_VERIFIED, SUCCESS_PASSWORD_RESET, FAIL_EMAIL, CREATE_ACCOUNT, CREATE_ACCOUNT_FAIL, WRONG_CREDENTIALS } from "../actions";
import { api, apiBackend } from "../../Config/config";
import axios from "axios";

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (data) => {
      // Request failed with status code 400
      if (data?.data?.message == "API_CREDENTIAL_INCORRECT"){
        dispatch({
          type: WRONG_CREDENTIALS,
          payload: data.data.message,
        });  
      }
      if (data?.data?.message == "API_PASSWORD_INCORRECT"){
        dispatch({
          type: WRONG_CREDENTIALS,
          payload: data.data.message,
        });  
      }
      if (data?.data?.message == ("API_FORBIDDEN")){
        dispatch({
          type: WRONG_CREDENTIALS,
          payload: data.data.message,
        });  
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.user,
      });
      return Promise.resolve();
    },
    (err) => {
      dispatch({
        type: LOGIN_FAIL,
        payload: err,
      });
      return Promise.reject();
    }
  );
};
export const verifyLogin = (token) => (dispatch) => {
  return AuthService.verifyToken(token).then(
    (data) => {
      console.log(data);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
      return Promise.resolve();
    },
    (err) => {
      dispatch({
        type: LOGIN_FAIL,
        payload: err,
      });
      return Promise.reject();
    }
  );
};

export const forgetPassword = (email) => {
  return (dispatch, getState) => {
    api
      (
        `forgetPasswordMail`,
        {
          method: "POST",
          data: { email }
        }
      )
      .then((res) => {
        res.data.email = email;
        if (res.data.message == "CODE.SENT.FOR.PASSWORD.RESET") {
          dispatch({
            type: FORGET_PASSWORD_EMAIL_SENT,
            payload: res.data,
          });
        }
        if (res.data.message == "EMAIL.NOT.VALID") {
          dispatch({
            type: FORGET_PASSWORD_EMAIL_SENT,
            payload: res.data,
          });
        }
      })
      .catch((err) => {

        dispatch({
          type: FORGET_PASSWORD_EMAIL_FAILED,
          payload: err,
        });
      });
  };
};

export const verifResetCode = (email, codeReset) => {
  return (dispatch, getState) => {
    api
      (
        `verifResetPasswordMail`,
        {
          method: "POST",
          data: { email: email, codeReset: codeReset }
        }
      )
      .then((res) => {
        console.log("DIGIT_VERIFIED DIGIT_VERIFIED DIGIT_VERIFIED", res.data);
        dispatch({
          type: DIGIT_VERIFIED,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
        // dispatch({
        //   type: DIGIT_VERIFIED,
        //   payload: res.data,
        // });
      });
  };
};

export const resetPassword = (email, newPassword) => {
  return (dispatch) => {
    api
      (
        `resetPassword`,
        {
          method: "POST",
          data: { email: email, newPassword: newPassword }
        }
      )
      .then((res) => {
        console.log("SUCCESS.PASSWORD.RESET", res.data);
        dispatch({
          type: SUCCESS_PASSWORD_RESET,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
        // dispatch({
        //   type: DIGIT_VERIFIED,
        //   payload: res.data,
        // });
      });
  };
};

export const createAccount = (firstName,lastName, email,phone, password) => {
  return (dispatch) => {
    api
      (
        `add_admin`,
        {
          method: "POST",
          data: { firstName: firstName, lastName: lastName, email: email, phone: phone, password: password, role : "admin" }
        }
      )
      .then((res) => {
        dispatch({
          type: CREATE_ACCOUNT,
          payload: res.data,
        });
      },(err) => {
        dispatch({
          type: CREATE_ACCOUNT_FAIL,
          payload: err,
        });
        return Promise.reject();
      })
      .catch((err) => {
        console.log("err", err);
        // dispatch({
        //   type: CREATE_ACCOUNT_FAIL,
        //   payload: err,
        // });
      });
  };
};