import React, { useState } from "react";
import { Pagination, Row, Table, Tag } from "antd";
import { PictureOutlined, YoutubeFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import PostDetails from "../../../Screens/posts/postDetails";

export default function ReportTable({
  reports,
  total,
  currentLimit,
  currentPage,
  onLimit,
  onPage,
  updateVisible,
  handleOpenUpdateModal,
}) {
  const history = useHistory();

  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedMotif, setSelectedMotif] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showPostDetails, setShowPostDetails] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      selectedRows?.length > 0 && setSelectedMotif(selectedRows[0]);
    },
    getCheckboxProps: (record) => ({
      //disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const report_motifs_columns = [
    {
      title: "Reported by",
      dataIndex: "categoryName",
      render: (text, motif) => (
        <a
          onClick={() => {
            history.push(`users-profile/${motif?.user_id}`);
          }}
        >
          {motif?.user_id}
        </a>
      ),
    },
    {
      title: "Post",
      dataIndex: "createdAt",
      render: (text, { post }) => (
        <a
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            handleOpenPostDetails();
            setSelectedPost(post);
          }}
          href="#"
        >
          <div>
            {
              //When the posts is refeechred we take the media from post_parent_id
              post?.post_parent_id?.medias?.images?.length > 0 ? (
                <img
                  style={{
                    width: "60px",
                    height: "55px",
                    borderRadius: "12px",
                    marginRight: "8px",
                  }}
                  src={post?.post_parent_id?.medias?.images[0]?.url}
                />
              ) : post?.post_parent_id?.medias?.videos?.length > 0 ? (
                <img
                  style={{
                    width: "60px",
                    height: "55px",
                    borderRadius: "12px",
                    marginRight: "8px",
                  }}
                  src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${post?.post_parent_id?.medias?.videos[0]?.thumbnail}`}
                />
              ) : post.medias?.images && post.medias?.images?.length ? (
                <img
                  style={{
                    width: "60px",
                    height: "55px",
                    borderRadius: "12px",
                    marginRight: "8px",
                  }}
                  src={post?.medias?.images[0]?.url}
                />
              ) : (
                post.medias?.videos &&
                post.medias?.videos?.length > 0 && (
                  <img
                    style={{
                      width: "60px",
                      height: "55px",
                      borderRadius: "12px",
                      marginRight: "8px",
                    }}
                    src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${post?.medias?.videos[0]?.thumbnail}`}
                  />
                )
              )
            }
          </div>
          <div style={{ maxWidth: "450px" }}>
            <p>{post.description}</p>
          </div>

          <div>{post?.type}</div>
        </a>
      ),
    },
    {
      title: "Motif",
      dataIndex: "motif",
      render: (text, report) => <p>{report?.motif?.tag_type?.en}</p>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text, report) => <p>{report?.createdAt}</p>,
    },
  ];
  const handleOpenPostDetails = () => {
    setShowPostDetails(!showPostDetails);
  };
  return (
    <div>
      <Table
        // rowSelection={{
        //   type: selectionType,
        //   ...rowSelection,
        // }}
        columns={report_motifs_columns}
        dataSource={reports}
        pagination={false}
      />
      {/* <Row justify={"end"} style={{ marginTop: "20px" }}>
        <Pagination
          className={"pagination"}
          defaultCurrent={1}
          current={Number(currentPage)}
          total={total}
          onChange={(page, pageSize) => {
            if (page !== currentPage) onPage(page);
            if (pageSize !== currentLimit) onLimit(pageSize);
          }}
        />
      </Row>
      <UpdateMotif
        visible={updateVisible}
        open={handleOpenUpdateModal}
        item={selectedMotif}
      /> */}
      {selectedPost && (
        <PostDetails
          title={"Reported Post"}
          visible={showPostDetails}
          open={handleOpenPostDetails}
          item={selectedPost}
        />
      )}
    </div>
  );
}
