import { Col, Divider, Row, Button, Skeleton } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { posts_filters, user_activities_actions } from "../../Commun/constant";
import FilterButonGroup from "../../Components/FilterButton/FilterButonGroup";
import CardStat from "../../Components/OverView/CardStat/cardStat";
import UserProfileHeader from "../../Components/Users/userProfileHeader";
import UsersActivityTable from "../../Components/Users/usersActivityTable";
import { fetch_posts } from "../../redux/posts/action";
import UsersService from "../../Services/UsersService";
const UserProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, postsList, total_posts, error, filters } = useSelector(
    (state) => state.postsReducer
  );
  const [user, setUser] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({});
  const [selectedItems, setSelectedItems] = useState(null);

  // const [fetchPosts, { data, loading, error }] = useMutation(FETCH_USER_POSTS);
  const handlePageChange = useCallback(
    (page) => {
      setPage(page);

      setFilter({ ...filter, page: page });
    },
    [page , filter]
  );
  
  const handleLimitChange = useCallback(
    (limit) => {
      setLimit(limit);

      setFilter({ ...filter, limit: limit });
    },
    [limit, filter]
  );
  
  const handleFilterChange = useCallback(
    (filteroptions) => {
      setFilter(filteroptions);
      setPage(1);

    },
    [filter]
  );
  const fetch_selected_user = () => {
   
    UsersService.getUserById(id)
      .then((data) =>{ 
        setUser(data.data)})
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    fetch_selected_user();
    return () => {};
  }, []);
  useEffect(() => {
    console.log("USER ACTIVITIES FILTERS : ", filter);
    dispatch(fetch_posts(id, filter));
    return () => {};
  }, [filter]);
  return (
    user && (
      <div>
        <UserProfileHeader user={user} />
        <Row className={"title"}>Analytics Overview</Row>
        <Row justify={"space-between"}>
          <CardStat
            xs={24}
            md={4}
            title={"Total Views"}
            counter={user?.views_count}
          />
          <CardStat
            xs={24}
            md={4}
            title={"Total Likes"}
            counter={user?.likes_count}
          />
          <CardStat xs={24} md={4} title={"Total Refeechr"} counter={1235} />
          <CardStat xs={24} md={4} title={"Total Comments"} counter={10000} />
          <CardStat xs={24} md={4} title={"Total Shares"} counter={30} />
        </Row>

        <Row>
          {error ? (
            <div>error</div>
          ) : isLoading ? (
            <Skeleton />
          ) : (
            <div style={{ width: "100%" }}>
              <Row className={"title"}>All Activity</Row>
              <Row>
                <Col xs={6} lg={10} xxl={9}>
                  <FilterButonGroup
                    filters={posts_filters}
                    onFilter={handleFilterChange}
                  />
                </Col>
                <Col span={1}>
                  <Divider
                    type="vertical"
                    style={{
                      width: "2px",
                      height: "90%",
                      backgroundColor: "lightGray",
                    }}
                  />
                </Col>
                <Col xs={6} lg={8} xxl={12}>
                  <ActionButtonGroup
                    actions={user_activities_actions}
                    items={selectedItems}
                  />
                </Col>
              </Row>

              <UsersActivityTable
                data={postsList}
                onLimit={handleLimitChange}
                onPage={handlePageChange}
                onFilter={handleFilterChange}
                currentLimit={limit}
                total={total_posts}
                page={page}
              />
            </div>
          )}
        </Row>
      </div>
    )
  );
};

export default UserProfile;
const ActionButtonGroup = ({ actions, items }) => {
  const [selectedAction, setSelectedAction] = useState(-1);
  const [options, setOptions] = useState(items);
  const handleActionChange = (index, filter) => {
    setSelectedAction(index);
  };
  useEffect(() => {
    setOptions(items);
    return () => {};
  }, [items]);
  return (
    <Row className={"btn-group"}>
      {items?.length <= 1 ? (
        actions.map((x, index) => (
          <Button
            className={selectedAction === index && "selected-btn"}
            key={index}
          >
            {x}
          </Button>
        ))
      ) : ""}
    </Row>
  );
};
