import { Avatar, Row, Col, Typography } from "antd";
import ReactPlayer from "react-player";
import {
  AntDesignOutlined,
  CheckCircleFilled,
  StarFilled,
  YoutubeFilled,
  PictureOutlined,
} from "@ant-design/icons";

const { Paragraph, Text } = Typography;
export const user_filters = [
  "All users",
  "Blocked users",
  "Verified",
  "Talent",
];
export const user_activities_filters = [
  "All Activity",
  "Posts",
  "Refeecher",
  "Comments",
  "Likes",
];
export const user_activities_actions = [
  "View Post",
  "Copy URL",
  "Block Post",
  "View Post Reports",
];
export const posts_filters = [
  "All Posts",
  "Feechred",
  "Feechring",
  "Re-Feechr",
];
export const Audio_actions = ["Add Audio", "Update Audio"];
export const category_actions = ["Add Category", "Update Category"];
export const report_motif_actions = ["Add Motif", "Update Motif"];
