import { Col, Modal, Row, Image, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import CommentsCard from "../../Components/Posts/CommentsCard";
import PostCard from "../../Components/Posts/PostCard";
import PostsService from "../../Services/PostsService";
import ReactPlayer from "react-player";
import randomColor from "randomcolor";
const CategoryDetails = ({ visible, open, item }) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  console.log("IM THE ITEM ================> :: ", item);
  // const fetch_post_by_id = (id) => {
  //   PostsService.getPostById(id)
  //     .then((data) => {
  //       setPost(data);
  //       setIsLoading(false);
  //     })
  //     .catch((e) => setError(e));
  // };
  // useEffect(() => {
  //   item && fetch_post_by_id(item._id);
  //   return () => {
  //     setIsLoading(true);
  //   };
  // }, [item]);
  return (
    <div>
      <Modal
        centered
        title={"Category Details"}
        visible={visible}
        onOk={() => open(null)}
        onCancel={() => open(null)}
        footer={null}
        width={"60%"}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Image
              src={
                process.env.REACT_APP_BASE_S3_VIDEO_URL + item.categoryImageUrl
              }
            />
            {/* <ReactPlayer
              style={{
                position: "absolute",

                bottom: 0,
              }}
              width={400}
              height={50}
              url={process.env.REACT_APP_BASE_S3_VIDEO_URL + item.s3Url}
              // light={`https://digit-u-media-resources.s3.amazonaws.com/1640774993638-jpeg`}
              controls={true}
            /> */}
          </Col>
          <Col span={12}>
            <Space
              style={{
                padding: 20,
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
              }}
              direction="vertical"
            >
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Name
                </Col>
                <Col xs={12}>{item?.categoryName}</Col>
              </Row>

              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Supported language
                </Col>
                <Col xs={12}>
                  <Row gutter={[16, 16]} style={{ flex: "flexWrap" }}>
                    {Object.keys(item.language).map((x) => (
                      <Col>
                        <Tag
                          color={randomColor({
                            luminosity: "bright",
                            format: "rgb", // e.g. 'rgb(225,200,20)'
                          })}
                        >
                          {x}
                        </Tag>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row style={{}} justify="space-around">
                <Col xs={12} className={"level-two-title"}>
                  Status
                </Col>
                <Col xs={12}>
                  {item?.active === "Y" ? "Enabled" : "Disabled"}
                </Col>
              </Row>
              <Row style={{}}>
                <Col
                  xs={12}
                  className={"level-two-title"}
                  style={{ textAlign: "left" }}
                >
                  <div style={{ alignSelf: "left" }}> Date </div>
                </Col>
                <Col xs={12}>{item.createdAt}</Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default CategoryDetails;
