import React, { useState, useEffect, useCallback } from "react";
import HashTagsTable from "../../Components/hashTags/hashTagsTable"
import ClubTable from "../../Components/clubs/clubTable"
import hashTagsServices from "../../Services/HashTagService";

import { SearchOutlined } from "@ant-design/icons";
import { Skeleton, Input, Col, Row, Button, Radio } from "antd";
import { useSelector } from "react-redux";
// import AddMusic from "./AddMusic";

export default function HashTags() {
  const { period } = useSelector((state) => state.usersReducer);

  const [hashtagList, setHashtagList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  // const [addMusicModal, setAddMusicModal] = useState(false);
  const [updateVerificationModal, setUpdateVerificationModal] = useState(false);

  const [page, setPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState(null);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(null);
  const [tag, setTag] = useState("");
  const [searchTag, setSearchTag] = useState(null);
  const [value, setValue] = useState("all");


  const fetch_hashTags_list = (limit, page, searchTag) => {
    setError(false);
    setIsLoading(true);

    hashTagsServices.fetchHashtags(limit, page, searchTag)
      .then(({ data }) => {
        setHashtagList(data.data);
        setTotalItems(data.metadata[0]?.totalItems || 0);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  };

  const onChange = (e) => {
    setValue(e.target.value);
    setTag(e.target.value);
  };

  const handleOpenAddModal = useCallback(
    // (status) => {
    //   setAddMusicModal(!addMusicModal);
    //   if (status) fetch_audio(1, limit, filter);
    // },
    // [addMusicModal]
  );

  const handleOpenUpdateModal = useCallback(
    // (status) => {
    //   setUpdateMusicModal(!updateMusicModal);
    //   if (status) fetch_audio(1, limit, filter);
    // },
    // [updateMusicModal]
  );

  const handleActions = useCallback((action) => {
    // setAddMusicModal(!addMusicModal);
    switch (action) {
      // case "Add Audio": {
      //   setAddMusicModal(!addMusicModal);
      //   return;
      // }
      case "Update Request": {
        setUpdateVerificationModal(!updateVerificationModal);
        return;
      }
    }
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      console.log("HELLO TEST FILTER FROM PAGE");
      setPage(page);
      setFilter({ ...filter, page });
    },
    [page, filter]
  );

  const handleLimitChange = useCallback(
    (limit) => {
      console.log("HELLO TEST FILTER FROM LIMIT");
      setLimit(limit);
      setFilter({ ...filter, limit });
    },
    [limit]
  );
  const handleSearch = useCallback(
    (text) => {
      console.log("HELLO TEST FILTER FROM SERACH", text.target.value);

      if (text.target.value.length > 0) {
        console.log("there is a text");
        setSearchTxt(text.target.value);
        setPage(1);
        setFilter({ ...filter, searchTxt: text.target.value, page: 1 });
      } else {
        console.log("there is no text");

        setSearchTxt(null);
        let newFilter = filter;
        delete newFilter.searchTxt;
        newFilter.page = 1;
        console.log("there is no text", newFilter);

        setFilter(newFilter);
      }
    },
    [searchTxt]
  );

  useEffect(() => {
    console.log("HANDLE CHANGE ");
    // setSearchTag(searchTxt);
    fetch_hashTags_list(limit, page, searchTxt);

    return () => { };
  }, [filter, searchTxt]);

  useEffect(() => {
    console.log("HANDLE CHANGE ", period);
    fetch_hashTags_list(limit, page, searchTxt);
    return () => { };
  }, [period]);

  return (
    <div>

      {/* search bar */}
      <Row
        style={{ marginBottom: "20px" }}
        align={"middle"}
        justify={"space-between"}
      >
        <Col>
          {/* <ActionButtonGroup
            actions={Audio_actions}
            handleSelectedAction={handleActions}
          /> */}
        </Col>
        <Col>
          <Input
            style={{
              borderRadius: "20px",
              width: "150px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
            size="default"
            placeholder="Search"
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>

      {error ? (
        <div>error</div>
      ) : isLoading ? (
        <Skeleton />
      ) : (
        <HashTagsTable
          data={hashtagList}
          total={totalItems}
          currentPage={page}
          currentLimit={limit}
          onPage={handlePageChange}
          onLimit={handleLimitChange}
        // updateVisible={updateMusicModal}
        // handleOpenUpdateModal={handleOpenUpdateModal}
        />
      )}
      {/* <AddMusic visible={addMusicModal} open={handleOpenAddModal} /> */}
    </div>
  );
}
const ActionButtonGroup = ({ actions, items, handleSelectedAction }) => {
  const [selectedAction, setSelectedAction] = useState(-1);
  const [options, setOptions] = useState(items);
  const handleActionChange = (index, action) => {
    setSelectedAction(index);
    handleSelectedAction(action);
  };
  useEffect(() => {
    setOptions(items);
    return () => { };
  }, [items]);
  return (
    <Row className={"btn-group"}>
      {actions.map((x, index) => (
        <Button
          onClick={() => handleActionChange(index, x)}
          className={"selected-btn "}
          key={index}
        >
          {x}
        </Button>
      ))}
    </Row>
  );
};
