import React, { useState, useEffect } from "react";
import { Col, Row, Card, Avatar, Typography, Skeleton } from "antd";
import "./top_users_card.css";
import { AntDesignOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import UsersService from "../../../Services/UsersService";
const gridStyle = {};
const { Paragraph } = Typography;
const TopUsersCard = () => {
  const [topUsers, setTopUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const fetch_top_uses = () => {
    UsersService.getTopUsers()
      .then(({ data }) => {
        setIsLoading(false);
        setTopUsers(data);
      })
      .catch((e) => {
        setError(true);
        setTopUsers([]);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetch_top_uses();
    return () => {};
  }, []);
  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={12}
      xl={12}
      className={"top-users"}
      style={{ padding: "20px", backgroundColor: "white" }}
    >
      <Card title="Top Users" className={"card_container customheader "}>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 7 }} />
        ) : error ? (
          <div>error</div>
        ) : (
          topUsers.map((x, index) => (
            <Row key={index} className={"item-top-user"}>
              <Col style={gridStyle} md={12} lg={12}>
                <Row gutter={16} align={"middle"}>
                  <Col>
                    <Avatar
                      size={30}
                      icon={<AntDesignOutlined />}
                      src={`https://digit-u-media-resources.s3.eu-central-1.amazonaws.com/${x.profile_image}`}
                    />
                  </Col>
                  <Col span={15}>
                    <Paragraph
                      className={"top-user-fields"}
                      ellipsis={{
                        rows: 1,
                      }}
                    >
                      <a
                        onClick={() =>
                          history.push(`users-profile/${x.user_id}`)
                        }
                      >
                        {x.fullName}
                      </a>
                    </Paragraph>
                  </Col>
                  <Col>
                    <Avatar size={15} icon={<AntDesignOutlined />} />
                  </Col>
                </Row>
              </Col>
              <Col className={"top-user-fields"} span={5}>
                Content 1
              </Col>
              <Col span={5} className={"top-user-fields"}>
                {`${x.views_count} Views`}
              </Col>
            </Row>
          ))
        )}
      </Card>
    </Col>
  );
};

export default TopUsersCard;
