import React, { useState, useCallback } from "react";
import { Pagination, Row, Table, Tag, Radio, Button, Modal, Col, Divider, Image, Space } from "antd";
import hashTagsServices from "../../Services/HashTagService";
import Moment from "moment";





const size = 40;
export default function HashTagsTable({
    data,
    total,
    currentLimit,
    currentPage,
    onLimit,
    onPage,
    updateVisible,
    handleOpenUpdateModal,
}) {
    const [selectionType, setSelectionType] = useState("checkbox");
    const [selectedMotif, setSelectedMotif] = useState(null);
    const [showPostDetails, setShowPostDetails] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [error, setError] = useState(false);
    const [selectDisableButton, setselectDisableButton] = useState(false);





    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
            selectedRows?.length > 0 && setSelectedMotif(selectedRows);
            if (selectedRows.length > 0) {
                setselectDisableButton(true)
            } else {
                setselectDisableButton(false)
            }
        },
        getCheckboxProps: (record) => ({
            //disabled: record.name === "Disabled User",
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const verif_columns = [
        {
            title: "",
            dataIndex: "medias",
            render: (media) =>
                // media?.images.length > 0 ? (
                //   <PictureOutlined style={{ fontSize: "20px" }} />
                // ) : media?.videos.length > 0 ? (
                //   <YoutubeFilled style={{ fontSize: "20px" }} />
                // ) :
                null,
        },
        {
            title: "Hashtag",
            dataIndex: "tag",
        },
        {
            title: "Post Count",
            dataIndex: "posts_count",
            responsive: ["md"],
        },
        {
            title: "Creation date",
            dataIndex: "createdAt",
            render: (text, post) => (
                <div>
                    {/* Moment( "2013-2-1 14:00:00" ).format( "dddd h:mma D MMM YYYY" ) ); */}
                    {Moment(`${post.createdAt}`).format("dddd h:mma D MMM YYYY")}
                </div>
            ),
        },

    ];

    const handleOpenPostDetails = useCallback(
        (audio) => {
            setShowPostDetails(!showPostDetails);
            !showPostDetails === true
                ? setSelectedMotif(audio)
                : setSelectedMotif(false);
        },
        [showPostDetails]
    );


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        let clubArray = [];
        for (let i = 0; i < selectedMotif.length; i++) {
            clubArray.push(selectedMotif[i]._id);
        }
        hashTagsServices.deleteHashtags(clubArray)
            .then(({ data }) => {
                setIsModalVisible(false);
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
                setError(true);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    return (
        <div className="tableContainer" >
            <Button type="primary" onClick={showModal} disabled={!selectDisableButton} >
                Delete hashtag
            </Button>
            <Modal title="Delete event" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                would you delete the following selected hashtag(s) ?
            </Modal>
            <Divider />
            <Table
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                columns={verif_columns}
                dataSource={data?.map((x, i) => ({ ...x, key: i }))}
                pagination={false}
            />
            <Row justify={"end"} style={{ marginTop: "20px" }}>
                <Pagination
                    className={"pagination"}
                    defaultCurrent={1}
                    current={Number(currentPage)}
                    total={total}
                    onChange={(page, pageSize) => {
                        if (page !== currentPage) onPage(page);
                        if (pageSize !== currentLimit) onLimit(pageSize);
                    }}
                />
            </Row>

           
            {/* <ConfirmVerification
                visible={updateVisible}
                open={handleOpenUpdateModal}
                item={selectedPost}
            /> */}
        </div>
    );
}
