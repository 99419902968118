import React from "react";
import LoginComponent from "../Screens/Authentification/LoginComponent";
import ForgetComponent from "../Screens/Authentification/ForgetComponent"
import ResetComponent from "../Screens/Authentification/ResetComponent"
import CreateAccountComponent from "../Screens/Authentification/createAccountComponent"
import { Redirect, Route, Switch } from "react-router-dom";


const UserRoute = (props) => {
  return (
    <div>
      <Switch>
        <Redirect exact from={`${props.match.url}/`} to={`${props.match.url}/login`} />
        <Route path={`${props.match.url}/login`} component={LoginComponent} />
        <Route path={`${props.match.url}/forget-password`} component={ForgetComponent} />
        <Route path={`${props.match.url}/reset-password`} component={ResetComponent} />
        <Route path={`${props.match.url}/create-account`} component={CreateAccountComponent} />
        <Redirect to="/errorrg" />
      </Switch>
    </div>
  );
};

export default UserRoute;
